import React, { useState } from 'react';
import PrismicRichText from '../components/PrismicRichText';
import { Grid } from '@material-ui/core';
import { get } from 'lodash';
import Dialog from '@material-ui/core/Dialog';

export default function VideoThumbSlice({ slice }) {
  const [openVideo, setOpenVideo] = useState(false);

  const backgroundStyle = {
    backgroundColor: slice.primary.background_color,
    color: slice.primary.text_color
  };

  const handleOpenVideo = url => {
    setOpenVideo(url);
  };
  const handleCloseVideo = () => {
    setOpenVideo(false);
  };

  return (
    <>
      <Dialog open={openVideo} onClose={handleCloseVideo} controlsList="nodownload" qBN>
        <video controls>
          <source src={openVideo} type="video/mp4" />
        </video>
      </Dialog>
      <section className="video-thumbs-slice section-padding" style={backgroundStyle}>
        <div className="wrapper">
          <div className="text-content">
            <PrismicRichText render={slice.primary.content} />
          </div>

          <div className="videos-list">
            <Grid container spacing={4}>
              {slice.items.map((el, i) => {
                const internalVideoURL = get(el, 'internal_video_url.url');
                const externalVideoURL = get(el, 'external_video_url.url');
                return (
                  <Grid key={i} item xs={12} md={6}>
                    <a
                      onClick={
                        internalVideoURL
                          ? () => {
                              handleOpenVideo(internalVideoURL);
                            }
                          : () => {
                              window.open(externalVideoURL, '_blank');
                            }
                      }>
                      <div className="video">
                        <img
                          className="video-thumbnail"
                          src={el.video_thumbnail.url}
                          alt={el.video_thumbnail.alt}
                        />
                        <PrismicRichText render={el.content} />
                      </div>
                    </a>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
      </section>
    </>
  );
}
