import { combineReducers } from 'redux';

import prismic from './prismic';
import loading from './loading';
import cookieCompliance from './cookie_compliance';
import accessibility from './accessibility';
import dialog from './dialog';

const rootReducer = combineReducers({
  prismic,
  loading,
  cookieCompliance,
  accessibility,
  dialog
});

export default rootReducer;
