import React from 'react';
import { get, map } from 'lodash';
import PrismicRichText from '../components/PrismicRichText';
import { styled } from 'styled-components';
import classNames from 'classnames';
import llrGoldContent from '../imagesV2/LLR/gold-splash-content.png';

const StlIconWrapper = styled.div`
  margin: 20px auto;
  figure {
    width: 100px;
    height: 100px;
    margin: auto;
    margin-bottom: 10px;
  }
  h4 {
    font-size: 1.4rem;
    text-transform: capitalize;
    white-space: nowrap;
    text-align: center;
  }

  .icon-copy {
    max-width: 224px;
    margin: auto;
  }

  @media only screen and (min-width: 800px) {
    figure {
      width: 75px;
      height: 75px;
    }
    .icon-copy {
      max-width: 305px;
      p {
        font-size: 1.6rem;
      }
    }
    h4 {
      font-size: 2.6rem;
    }
  }
`;

const renderIcons = icons => {
  return map(icons, (el, i) => (
    <StlIconWrapper key={i}>
      <figure className="contain-img height-auto">
        <img alt="" role="presentation" src={get(el, 'image.url')} />
      </figure>
      <div className="icon-copy">
        <PrismicRichText render={get(el, 'content')} />
      </div>
    </StlIconWrapper>
  ));
};

const LLRInfo = ({ slice }) => {
  const background = get(slice, 'primary.background_style');
  const showBackground = background === 'Dark Flower' ? 'dark-flower' : 'dark-blue-splatter';
  const icons = get(slice, 'items');

  return (
    <div className={classNames(showBackground, 'llr-info')}>
      <figure className={classNames('llr-gold-stream-content cover-img')}>
        <img src={llrGoldContent} alt="" role="presentation" />
      </figure>
      <div className="background-wrapper llr-wrapper llr-content-padding">
        <div className="standard-content-section">
          <div className="llr-headline copy">
            <PrismicRichText render={get(slice, 'primary.content')} />
          </div>
        </div>

        <div className="info-icons">{renderIcons(icons)}</div>
      </div>
    </div>
  );
};

export default LLRInfo;
