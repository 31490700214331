import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import renderButtons from '../helpers/renderButtons';
import { Tween } from 'react-gsap';
import { get } from 'lodash';

const SpecialHeroV2 = ({slice}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('600'));

  const getCorrectHeroImage = background => {
    if (!background) {
      return false;
    }
    if (isMobile) {
      return background.Mobile;
    } else {
      return background;
    }
  };

  const backgroundImageURL = getCorrectHeroImage(slice.primary.hero_image).url;

  const backgroundColorStyle = {
    background: slice.primary.background_color
  };

  const mobileTextContent = get(slice, 'primary.mobile_content');

  return (
    <section className="hero-section special-hero-v2 cool-margin">
      <div className="wrapper">
        <Tween
          from={{ y: '-50', opacity: '0', scale: '1.05' }}
          stagger={0.3}
          duration={1}
          delay={0}
          ease="back.out">
          <img src={backgroundImageURL} alt="Cresco Cannabis Website Hero" className="hero-image" />
        </Tween>
        <div className="text-content">
          <Tween
            from={{ y: '-50', opacity: '0', scale: '1.05' }}
            stagger={0.3}
            duration={1}
            delay={0.25}
            ease="back.out">
            <div>
              {isMobile && mobileTextContent.length !== 0 ? (
                <PrismicRichText field={slice.primary.mobile_content} />
              ) : (
                <PrismicRichText field={slice.primary.content} />
              )}
            </div>
            <div className="button-container">{renderButtons(slice.items)}</div>
          </Tween>
        </div>
      </div>

      <div className="background-color" style={backgroundColorStyle}></div>
    </section>
  );
};

export default SpecialHeroV2;
