import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

const Video = ({ video, className, poster }) => {
  const [videoEl, setVideoEl] = useState(false);
  const setRef = el => {
    setVideoEl(el);
  };
  useEffect(() => {
    if (videoEl) {
      videoEl.load();
    }
  }, [video, videoEl]);

  return (
    <div className={classNames('video-container', className)}>
      <video ref={setRef} poster={poster} autoPlay playsInline muted loop controlsList="nodownload">
        <source src={video}></source>
      </video>
      {/* <video ref={setRef}>
        <source src={video}></source>
      </video> */}
    </div>
  );
};

export default Video;
