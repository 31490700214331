import React from 'react';
import PrismicRichText from '../components/PrismicRichText';

export default function PhotoSection({ slice }) {
  const backgroundStyle = {
    backgroundColor: slice.primary.background_color,
    color: slice.primary.text_color
  };
  return (
    <section className="logo-section cool-margin" style={backgroundStyle}>
      <div className="wrapper">
        <PrismicRichText render={slice.primary.content} />
        <div className="logos">
          {slice.items.map((el, i) => (
            <a
              className="logo"
              key={i}
              href={el.link_url && el.link_url.url}
              target={el.link_url && el.link_url.target}>
              <img src={el.logo.url} alt="As seen in Logos" />
            </a>
          ))}
        </div>
      </div>
    </section>
  );
}
