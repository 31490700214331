import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Button } from '@material-ui/core';
import { get } from 'lodash';
import Link from '../Link';
import cx from 'classnames';
import { Switch, FormControlLabel } from '@material-ui/core';
import { styled } from 'styled-components';

const getOptions = (
  allowMarketing,
  setMarketing,
  allowPersonalization,
  setPersonalization,
  allowAnalytics,
  setAnalytics
) => [
  {
    label: 'Personalization',
    allow: allowPersonalization,
    setChoice: setPersonalization,
    detail: `Enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.`
  },
  {
    label: 'Marketing',
    allow: allowMarketing,
    setChoice: setMarketing,
    detail: `Used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.`
  },
  {
    label: 'Analytics',
    allow: allowAnalytics,
    setChoice: setAnalytics,
    detail: `Help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.`
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: '10000000',
    background: `#00000055`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& h2, h3': { color: 'black' },
    '& h2': {
      fontSize: '20px'
    },
    '& h3': {
      fontSize: '16px'
    },
    '& p': {
      fontSize: '12px',
      lineHeight: '16px',
      color: 'black'
    },
    '& > div': {
      maxHeight: '90%',
      overflow: 'auto',
      position: 'relative',
      maxWidth: '516px',
      display: 'flex',
      flexDirection: 'column',
      margin: '10px',
      padding: '50px',
      background: 'white',
      [theme.breakpoints.down('xs')]: {
        padding: '30px'
      },
      '& label': {
        marginLeft: '0'
      },
      '& > *': {
        marginBottom: '20px'
      },
      '& > *:last-child': {
        marginBottom: '0'
      },
      '& h3': {
        marginBottom: '12px'
      }
    }
  },
  closeIcon: {
    position: 'absolute',
    top: 15,
    right: 15,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  button: {
    marginBottom: '0 !important'
  }
}));

const StlFormControlLabel = styled(FormControlLabel)`
  &.custom-label {
    position: relative;
    .MuiFormControlLabel-label {
      position: absolute;
      left: 8px;
      font-size: 12px;
      color: white;
    }
    &.label-disabled {
      .MuiFormControlLabel-label {
        color: #979797;
        right: 8px;
        left: auto;
      }
    }
    .MuiSwitch-track {
      background: #e0e0e0;
      opacity: 1;
    }
  }
`;

const StlBannerWrapper = styled('div')`
  background: white;
  padding: 24px 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.35);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: stretch;
  .MuiButton-root {
    width: 100%;
    max-width: none;
    font-size: 12px;
  }
  p {
    margin: 0;
    color: black;
  }
  @media (min-width: 500px) {
    padding: 24px 42px;
    p {
      font-size: 12px;
    }
    .MuiButton-root {
      min-width: 180px;
    }
  }
  @media (min-width: 900px) {
    align-items: left;
    flex-direction: row;
  }
`;

const StlButton = styled(Button)`
  margin: 0;
  height: 48px;
  @media (max-width: 500px) {
    align-self: center;
  }
  @media (max-width: 900px) {
    width: 128px;
  }
`;
const StlDisclaimer = styled('div')`
  text-align: center;
  margin-bottom: 24px;
  @media (min-width: 500px) {
    text-align: left;
  }
  @media (min-width: 900px) {
    margin-right: 48px;
    margin-bottom: 0;
  }
`;

const StlButtonContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-gap: 16px;
  grid-row-gap: 24px;
  text-align: center;
  @media (min-width: 500px) {
    grid-template-columns: auto 1fr 1fr;
    grid-template-rows: 1fr;
    align-items: center;
  }
`;

const StlDetailsLink = styled('span')`
  cursor: pointer;
  grid-row-start: 2;
  grid-column-start: 1;
  grid-column-end: 3;
  white-space: nowrap;
  color: black;
  font-family: sans-serif;
  text-decoration: underline;
  @media (min-width: 500px) {
    grid-row-start: 1;
    grid-column-end: 2;
    align-self: center;
  }
  @media (min-width: 900px) {
    min-width: none;
  }
`;

const getMarketingDefault = complianceSettings =>
  complianceSettings.needsToAccept ? true : complianceSettings.marketing;
const getAnalyticsDefault = complianceSettings =>
  complianceSettings.needsToAccept ? true : complianceSettings.analytics;
const getPersonalizationDefault = complianceSettings =>
  complianceSettings.needsToAccept ? true : complianceSettings.personalization;

export default function CookieComplianceDialog() {
  const dispatch = useDispatch();
  const showDialog = useSelector(state => get(state, 'cookieCompliance.show'));

  const showDetails = () => {
    dispatch({
      type: 'COOKIE_COMPLIANCE/SHOW_DETAIL'
    });
  };

  const acceptAllSettings = () => {
    dispatch({
      type: 'COOKIE_COMPLIANCE/SAVE',
      marketing: true,
      personalization: true,
      analytics: true
    });
  };

  const declineAllSettings = () => {
    dispatch({
      type: 'COOKIE_COMPLIANCE/SAVE',
      marketing: false,
      personalization: false,
      analytics: false
    });
  };

  if (!showDialog) {
    return null;
  } else if (showDialog === 'detail') {
    return <ComplianceDetails />;
  } else {
    return (
      <StlBannerWrapper>
        <StlDisclaimer>
          <p>
            This website stores data such as cookies to enable important site functionality
            including analytics, targeting, and personalization. You may alter your cookie
            preferences at any time or accept the default settings. Read our{' '}
            <Link color="inherit" className="underline-link" to="/privacy-policy">
              Privacy Policy
            </Link>{' '}
            for more information.
          </p>
        </StlDisclaimer>
        <StlButtonContainer>
          <StlDetailsLink onClick={showDetails} className="underline-link nowrap">
            Manage Cookie Settings
          </StlDetailsLink>
          <StlButton
            size="small"
            color="secondary"
            variant="contained"
            onClick={declineAllSettings}>
            Decline All
          </StlButton>
          <StlButton size="small" color="secondary" variant="contained" onClick={acceptAllSettings}>
            Accept All
          </StlButton>
        </StlButtonContainer>
      </StlBannerWrapper>
    );
  }
}

function ComplianceDetails() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const showDialog = useSelector(state => get(state, 'cookieCompliance.show'));
  const settings = useSelector(state => get(state, 'cookieCompliance'));
  const [marketing, setMarketing] = useState(getMarketingDefault(settings));
  const [personalization, setPersonalization] = useState(getPersonalizationDefault(settings));
  const [analytics, setAnalytics] = useState(getAnalyticsDefault(settings));

  const ccpaChoices = getOptions(
    marketing,
    setMarketing,
    personalization,
    setPersonalization,
    analytics,
    setAnalytics
  );

  const saveSettings = () => {
    dispatch({
      type: 'COOKIE_COMPLIANCE/SAVE',
      marketing,
      personalization,
      analytics
    });
  };

  const closeDetails = () => {
    dispatch({
      type: 'COOKIE_COMPLIANCE/HIDE'
    });
  };

  if (!showDialog) {
    return null;
  }
  return (
    <div className={classes.root}>
      <Paper>
        <h2>Cookie Settings</h2>
        {ccpaChoices.map((choice, key) => (
          <ComplianceChoice
            className="mb-30"
            key={key}
            label={choice.label}
            detail={choice.detail}
            allow={choice.allow}
            setChoice={choice.setChoice}
          />
        ))}
        <p className="disclaimer text-info">
          For more info, view the <Link to="/privacy-policy">Privacy Policy</Link>.
        </p>
        <Button
          className={classes.button}
          color="secondary"
          size="large"
          variant="contained"
          onClick={saveSettings}>
          Save Settings
        </Button>
        <div className={classes.closeIcon} onClick={closeDetails}>
          X
        </div>
      </Paper>
    </div>
  );
}

function ComplianceChoice({ label, setChoice, allow, detail, className }) {
  return (
    <div className={className}>
      <div
        style={{ display: 'flex', justifyContent: 'space-between' }}
        className="flex-row-spaced mb-12">
        <h3>{label}</h3>
        <StlFormControlLabel
          label={allow ? 'On' : 'Off'}
          className={allow ? 'custom-label label-enabled' : 'custom-label label-disabled'}
          labelPlacement="start"
          control={<Switch color="primary" checked={allow} onChange={() => setChoice(!allow)} />}
        />
      </div>
      <p style={{ fontSize: '12px', lineHeight: '1.2' }}>{detail}</p>
    </div>
  );
}
