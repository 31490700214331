import React from 'react';
import PrismicRichText from '../components/PrismicRichText';
import { get } from 'lodash';
import { Carousel } from 'react-responsive-carousel';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Link from './../components/Link';
export default function PartnerSlice({ slice }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('900'));

  const backgroundStyle = {
    color: slice.primary.text_color,
    backgroundColor: slice.primary.background_color,
    backgroundImage: `url(${get(slice, 'primary.background_image.url')})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover'
  };

  return (
    <section className="fact-slice section-padding" style={backgroundStyle}>
      <div className="wrapper">
        <div className="text-content">
          <PrismicRichText render={slice.primary.content} />
        </div>

        {isMobile ? (
          <div className="partners">
            <Carousel thumbs={false} showStatus={false} showIndicators={false} showArrows={true}>
              {slice.items.map((partner, i) => (
                <div className="partner-slide">
                  <Link
                    url={get(partner, 'partner_website.url')}
                    target={get(partner, 'partner_website.target')}
                    className="partner-logo-link">
                    <img
                      src={get(partner, 'partner_logo.url')}
                      alt={get(partner, 'partner_logo.alt')}
                    />
                  </Link>
                  <div className="partner-content">
                    <PrismicRichText render={partner.content} key={i} />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        ) : (
          <div className="partners">
            {slice.items.map((partner, i) => (
              <div className="partner-slide">
                <Link
                  url={get(partner, 'partner_website.url')}
                  target={get(partner, 'partner_website.target')}
                  className="partner-logo-link">
                  <img
                    src={get(partner, 'partner_logo.url')}
                    alt={get(partner, 'partner_logo.alt')}
                  />
                </Link>
                <div className="partner-content">
                  <PrismicRichText render={partner.content} key={i} />
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="text-content-bottom">
          <PrismicRichText render={slice.primary.postscript_content} />
        </div>
      </div>
    </section>
  );
}
