import React from 'react';
import Helmet from 'react-helmet';
import metadata from '../../constants/seo.json';

export default function RenderMetaTags({ pathname }) {
  const path = pathname === '/' ? '/home' : pathname;
  const match =
    metadata.find(el => el.seo_slug === path) || metadata.find(el => el.seo_slug === '/home');

  if (!match) return null;

  return (
    <Helmet>
      <title>{match.seo_title}</title>
      <meta name="description" content={match.seo_description} />
    </Helmet>
  );
}
