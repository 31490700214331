import React from "react";

export default function FullWidthImageSlice({slice}) {
  return (
    <img
      src={slice.primary.image.url}
      className="full-width-image"
      alt="Full Width Graphic"
    />
  );
}
