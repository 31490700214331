import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import { ReactComponent as CalendarIcon } from '../../imagesV2/icons/ico-calendar.svg';

const DatePicker = ({ dates, onClick }) => {
  const [anchorEl, setAnchorEl] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const RenderUniqueDates = () => {
    const DateButton = ({ date }) => {
      return (
        <li className="datebutton-list-item">
          <button
            onClick={() => {
              onClick(date);
            }}>
            {date}
          </button>
        </li>
      );
    };
    return (
      <ul className="datebutton-list">
        {dates.map((date, i) => {
          return <DateButton key={i} date={date} />;
        })}
      </ul>
    );
  };

  if (!dates) {
    return null;
  }

  return (
    <div className="datepicker-wrapper">
      <button id="datepicker" aria-label="Open Date Picker for Blog Articles" onClick={handleClick}>
        <CalendarIcon style={{ width: '25px', height: '25px', cursor: 'pointer' }} />
      </button>
      <Menu
        id="positioned-menu"
        aria-labelledby="datepicker"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          style: {
            maxHeight: '250px'
          }
        }}>
        <div className="datepicker-title">Choose Articles From:</div>
        <RenderUniqueDates />
      </Menu>
    </div>
  );
};

export default DatePicker;
