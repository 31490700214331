import PrismicClient from './prismic-api';
import Prismic from 'prismic-javascript';

import NewClient from './prismic';

const fetchSinglePage = async pageName => {
  //try getting content by tags
  const prismicData = await PrismicClient.query([
    Prismic.Predicates.at('document.type', pageName)
    // Prismic.Predicates.at('document.tags', tags)
  ]).then(res => {
    return res.results[0];
  });
  if (prismicData) {
    // console.log("returned tagged data")
    return prismicData;
    //if the queried page exists, it will be returned
  } else {
    return false;
  }
};

const fetchSingleByUID = async (postType, pageUID) => {
  try {
    const prismicDocument = await PrismicClient.getByUID(postType, pageUID);
    if (prismicDocument) {
      return prismicDocument;
    } else {
      console.warn('error, not found etc.');
      return false;
    }
  } catch (error) {
    console.error(error);
  }
};

const fetchRepeatable = async postType => {
  const prismicData = await NewClient.getAllByType(postType);

  if (prismicData) {
    return prismicData;
  }
  return false;
};

export { fetchSingleByUID, fetchRepeatable, fetchSinglePage };
