import { createTheme } from '@material-ui/core/styles';

const themeObj = {
  palette: {
    primary: {
      main: '#ffffff'
    },
    secondary: {
      main: '#214a92'
    },
    error: {
      main: '#ad0000'
    }
  },
  typography: {
    fontFamily: 'Poppins-Regular'
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: 'transparent'
      }
    },
    MuiButton: {
      root: {
        fontSize: '1.4rem',
        fontFamily: 'Poppins-Semibold'
      },
      label: {
        whiteSpace: 'nowrap'
      }
    },
    MuiSvgIcon: {
      fontSizeLarge: {
        fontSize: '50px'
      }
    },
    MuiIconButton: {
      root: {
        padding: '0px'
      }
    },
    MuiSwitch: {
      root: {
        padding: '0',
        borderRadius: '18px',
        width: '53px',
        height: '24px'
      },
      thumb: {
        width: '18px',
        height: '18px',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15)'
      },
      switchBase: {
        padding: '3px',
        '&:hover': {
          backgroundColor: 'transparent'
        },
        '&&&&$checked': {
          transform: 'translateX(calc(100% + 5px))',
          color: 'white'
        },
        '&&&&$checked + $track': {
          opacity: '1',
          backgroundColor: '#214a92'
        },
        '&&&&$checked + &&&&$disabled + $track': {
          opacity: '.2'
        }
      }
    },
    MuiMenu: {
      list: {
        backgroundColor: 'rgba(2, 6, 10, 0.9)',
        padding: '15px',
        color: '#87c7f7'
      }
    },
    MuiMenuItem: {
      root: {
        fontFamily: 'Poppins-Semibold',
        textTransform: 'uppercase',
        fontSize: '1.4rem'
      }
    },
    MuiListItem: {
      button: {
        color: '#87c7f7'
      }
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#87c7f7'
        },
        color: '#87c7f7',
        fontSize: '1.6rem',
        textTransform: 'uppercase',
        fontFamily: 'Poppins-Semibold'
      }
    },
    MuiSvgIcon: {
      root: {
        color: '#87c7f7',
        fill: '#87c7f7',
        fontSize: '2rem'
      }
    },
    MuiFormControl: {
      root: {
        marginBottom: '.8rem'
      }
    },
    MuiSelectOutlined: {
      root: {
        fontSize: '2rem',
        color: 'white'
      }
    },
    MuiOutlinedInput: {
      root: {
        width: '80%',
        borderRadius: '0',
        '&$focused $notchedOutline': {
          borderColor: '#87c7f7 !important',
          borderWidth: '1px'
        },
        '&$active $notchedOutline': {
          borderColor: '#87c7f7 !important',
          borderWidth: '1px'
        },
        '&:hover $notchedOutline': {
          borderColor: '#87c7f7 !important',
          borderWidth: '1px'
        }
      },
      notchedOutline: {
        borderColor: '#87c7f7 !important',
        borderWidth: '1px',
        fontSize: '2rem'
      },
      input: {
        fontSize: '1.6rem',
        color: '#b9e0f7'
      }
    },
    MuiTableCell: {
      root: {
        borderTop: '1px solid #87c7f7'
      }
    }
  }
};

const theme = createTheme(themeObj);
export { theme, themeObj };
