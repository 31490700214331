import React from 'react';
import { get } from 'lodash';

export default function VideoSlice({slice}) {
  const videoURL = get(slice, 'primary.video_url.url');
  const videoPosterImage = get(slice, 'primary.video_poster.url');

  return (
    <div className="video-slice cool-margin">
      <video
        controls
        poster={videoPosterImage}
        controlsList="nodownload"
        muted
        loop
        id="home-video">
        <source src={videoURL} type="video/mp4" />
      </video>
    </div>
  );
}
