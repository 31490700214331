import React from 'react';
import { get, map } from 'lodash';
import PrismicRichText from '../components/PrismicRichText';
import classNames from 'classnames';
import { useTheme } from '@material-ui/core/styles';
import { styled } from 'styled-components';
import { SimpleLink } from '../helpers/renderButtonsV2';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import HorizontalSlider from '../components/HorizontalSlider';

const StlWrapper = styled.div`
  @media only screen and (min-width: 700px) {
    padding-bottom: 80px;
    .scroller {
      display: flex;
      justify-content: center;
    }
  }
  @media only screen and (min-width: 1200px) {
    padding-bottom: 150px;
  }
`;

const StlIconWrapper = styled.div`
  margin-right: 40px;
  figure {
    width: 70px;
    height: 70px;
    margin: auto;
    margin-bottom: 10px;
  }
  h4 {
    font-size: 1.4rem;
    text-transform: capitalize;
    white-space: nowrap;
    text-align: center;
  }

  @media only screen and (min-width: 1200px) {
    margin-right: 0;
    figure {
      width: 150px;
      height: 150px;
    }
    h4 {
      font-size: 1.4rem;
    }
  }
`;

const StlDesktopIconsWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 520px;
  gap: 28px;

  figure {
    width: 75px;
    height: 75px;
  }
`;

const renderIcons = icons => {
  return map(icons, (el, i) => (
    <StlIconWrapper key={i}>
      <figure className="contain-img height-auto">
        <img alt="" role="presentation" src={get(el, 'image.url')} />
      </figure>
      <PrismicRichText render={get(el, 'copy')} />
    </StlIconWrapper>
  ));
};

const DisplayIcons = ({ icons }) => {
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down('1200'));

  return isTabletDown ? (
    <HorizontalSlider scrollContainerClassName="scroller">{renderIcons(icons)}</HorizontalSlider>
  ) : (
    <StlDesktopIconsWrapper>{renderIcons(icons)}</StlDesktopIconsWrapper>
  );
};

const ContentWithSliderOnMobile = ({ slice }) => {
  const background = get(slice, 'primary.background_style');
  const showBackground = background === 'Dark Flower' ? 'dark-flower' : 'dark-blue-splatter';
  const icons = get(slice, 'items');

  return (
    <div className={classNames('content-slider-mobile', showBackground)}>
      <StlWrapper className="background-wrapper llr-wrapper">
        <div className="standard-content-section llr-hero-padding">
          <div className="llr-headline copy">
            <PrismicRichText render={get(slice, 'primary.content')} />
          </div>
        </div>
        {get(slice, 'primary.cta_link.uid') ||
          (get(slice, 'primary.cta_link.url') && (
            <div className="button-container">
              <SimpleLink
                to={get(slice, 'primary.cta_link.uid')}
                url={get(slice, 'primary.cta_link.url')}
                target={get(slice, 'primary.cta_link.target')}
                variant={get(slice, 'primary.button_variant')}
                text={get(slice, 'primary.cta_text[0].text')}
              />
            </div>
          ))}
        <div className="icons-wrapper">
          <DisplayIcons icons={icons} />
        </div>
      </StlWrapper>
    </div>
  );
};

export default ContentWithSliderOnMobile;
