import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import RenderShopButton from '../ShopButton';
import { ReactComponent as MobileIcon } from '../../imagesV2/icons/mobile-menu-on.svg';
import classNames from 'classnames';
import { styled } from 'styled-components';

const StlButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

export default function Header(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('900'));
  const [navOpen, setNavOpen] = useState(false);
  const activePage = props.activePage === '' ? '' : props.activePage;

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const componentLoading = useSelector(state => get(state, 'prismic.header.loading', true));
  const component = useSelector(state => get(state, 'prismic.header.data', false));
  const logo = get(component, 'logo.url', false);
  const navigationLinks = get(component, 'navigation', false);

  const renderNavLinks = navigationLinks => {
    return navigationLinks.map((el, i) => {
      return (
        <li key={i} className={activePage === `/${el.link_url.uid}` ? 'active' : undefined}>
          <Link
            onClick={() => {
              setNavOpen(false);
            }}
            to={`/${el.link_url.uid}`}>
            {el.link_text[0].text}
          </Link>
        </li>
      );
    });
  };

  return (
    !componentLoading &&
    component && (
      <>
        <header>
          <nav className={classNames(!navOpen && isMobile ? '' : 'openNav')}>
            <div className={'nav-wrapper'}>
              <div className="mobile-bar-wrapper">
                <a href="/" aria-label="homepage link">
                  <figure id="nav-logo" className="ml-25">
                    <img src={logo} alt="" role="presentation"></img>
                  </figure>
                </a>
                {isMobile && (
                  <StlButton
                    onClick={toggleNav}
                    className={`menu-icon ${navOpen ? 'animate-menu' : ''}`}
                    aria-label={!navOpen ? 'open navigation' : ''}>
                    <MobileIcon />
                  </StlButton>
                )}
              </div>
              <ul className={!navOpen && isMobile ? '' : 'openNav'}>
                {renderNavLinks(navigationLinks)}
                {isMobile && (
                  <>
                    <li>
                      <Link
                        to="/shop"
                        onClick={() => {
                          setNavOpen(false);
                        }}>
                        Buy Online
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/store-locator"
                        onClick={() => {
                          setNavOpen(false);
                        }}>
                        Store Locator
                      </Link>
                    </li>
                  </>
                )}
                {!isMobile && <RenderShopButton />}
              </ul>
            </div>
          </nav>
        </header>
      </>
    )
  );
}
