import React, { useState } from 'react';
import { PrismicRichText } from '@prismicio/react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import renderButtons from '../helpers/renderButtons';
import { Tween } from 'react-gsap';
import { get } from 'lodash';

const Hero = ({ slice }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('420'));
  const isTablet = useMediaQuery(theme.breakpoints.between('420', '768'));
  const isDesktop = useMediaQuery(theme.breakpoints.between('768', '1280'));
  const [imageLoaded, setImageLoaded] = useState(false);
  const extraClassname = get(slice, 'primary.extra_classname[0].text');

  const getCorrectBackground = background => {
    if (!background) {
      return false;
    }
    if (isMobile) {
      return background.Mobile;
    }
    if (isTablet) {
      return background.Tablet;
    }
    if (isDesktop) {
      return background.Desktop;
    } else {
      return background;
    }
  };

  const backgroundImageURL = getCorrectBackground(slice.primary.background_image).url;

  const backgroundImageStyle = {
    backgroundImage: 'url(' + backgroundImageURL + ')'
  };

  const backgroundColorStyle = {
    background: slice.primary.background_color
  };

  return (
    <section className={`hero-section cool-margin ${extraClassname && extraClassname} `}>
      <div className="wrapper">
        {imageLoaded && (
          <div className="text-content">
            <Tween
              from={{ y: '-50', opacity: '0', scale: '1.05' }}
              stagger={0.3}
              duration={1}
              ease="back.out"
              disabled>
              <div>
                <PrismicRichText field={slice.primary.content} />
              </div>
              <div className="button-container">{renderButtons(slice.items)}</div>
            </Tween>
          </div>
        )}
      </div>
      {imageLoaded && (
        <Tween from={{ x: '100', opacity: '0', scale: '1.2' }} duration={1} ease="circle.out">
          <div className="background-image" style={backgroundImageStyle}></div>
        </Tween>
      )}

      <div className="background-color" style={backgroundColorStyle}></div>

      {backgroundImageURL && (
        <img
          src={backgroundImageURL}
          style={{ display: 'none' }}
          onLoad={() => {
            setImageLoaded(true);
          }}
        />
      )}
    </section>
  );
};

export default Hero;
