import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { get } from 'lodash';
import { ReactComponent as UpArrow } from '../imagesV2/icons/up-arrow.svg';

const ImageCopyBlocks = ({slice}) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const background = get(slice, 'primary.background_image.url');
  const backgroundImage = {
    background: 'url(' + background + ')'
  };

  const renderBlocks = blocks => {
    return blocks.map((el, i) => (
      <div className="block-container" key={i}>
        <figure>
          <img src={get(el, 'image.url')} alt={get(el, 'image.alt')} />
        </figure>
        <div className={`text-content `}>
          <PrismicRichText field={get(el, 'copy')} />
        </div>
      </div>
    ));
  };
  return (
    <section className="image-copy-blocks section-padding-2 cool-margin">
      {background && <div className="background-image" style={backgroundImage}></div>}
      {renderBlocks(get(slice, 'items'))}
      <div className="scroll-top-wrapper">
        <UpArrow className="up-arrow up-arrow-white" />
        <button
          onClick={scrollToTop}
          className="back-btn back-btn-white"
          aria-label="back to beginning of article">
          BACK TO TOP
        </button>
      </div>
    </section>
  );
};
export default ImageCopyBlocks;
