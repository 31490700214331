import React from 'react';
import { get } from 'lodash';
import PrismicRichText from '../components/PrismicRichText';
import LazyLoadingImage from '../components/LazyLoadImage';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const DoubleContentWithImage = ({ slice }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('900'));
  const background = get(slice, 'primary.background_style');
  const showBackground = background === 'Dark Flower' ? 'dark-flower' : 'dark-blue-splatter';
  const firstContent = get(slice, 'primary.first_content_section');
  const secondContent = get(slice, 'primary.second_content_section');
  const mobileImage = get(slice, 'primary.image.mobile.url');
  const desktopImage = get(slice, 'primary.image.url');

  return (
    <div className={showBackground}>
      <div className="background-wrapper double-content-section">
        <div className="first-content padding">
          <PrismicRichText render={firstContent} />
        </div>
        <figure className="cover-img">
          <LazyLoadingImage
            wrapperClassName={!isMobile && 'border'}
            src={isMobile ? mobileImage : desktopImage}
            alt={
              isMobile ? get(slice, 'primary.image.Mobile.alt') : get(slice, 'primary.image.alt')
            }
          />
        </figure>
        <div className="second-content">
          <PrismicRichText render={secondContent} />
        </div>
      </div>
    </div>
  );
};

export default DoubleContentWithImage;
