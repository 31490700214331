import React from 'react';
import PrismicRichText from '../components/PrismicRichText';
import renderButtons from './../helpers/renderButtons';
import { get, isEmpty } from 'lodash';
import { styled } from 'styled-components';

const StlMainSection = styled.section`
  padding-top: 30px;
  .background-wrapper {
    max-width: 1350px;
    margin: auto;
  }
`;
const StlHeadingWrapper = styled.div`
  @media only screen and (min-width: 900px) {
    margin-bottom: 80px;
  }
`;

export default function TextSlice({ slice }) {
  const logo = get(slice, 'primary.logo.url');
  const heading = get(slice, 'primary.heading');

  return (
    <StlMainSection className="dark-flower background-repeat">
      <div className="background-wrapper padding-less">
        {logo && (
          <div className="text-slice-logo">
            <img src={logo} alt="" role="presentation" />
          </div>
        )}
        <div>
          {!isEmpty(heading) && (
            <StlHeadingWrapper className="text-center">
              <PrismicRichText render={heading} />
            </StlHeadingWrapper>
          )}
          <div className="text-left">
            <PrismicRichText render={get(slice, 'primary.content')} />
          </div>
          <div className="buttons">{renderButtons(get(slice, 'items'))}</div>
        </div>
      </div>
    </StlMainSection>
  );
}
