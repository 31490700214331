import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import renderButtons from '../helpers/renderButtons';
import { get } from 'lodash';

const HeroHalfImgContent = ({slice}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('768'));

  return (
    <section className="half-img-copy">
      <div className="copy">
        <div className="copy-wrapper">
          <PrismicRichText field={get(slice, 'primary.content')} />
          <div className="buttons"></div>
        </div>
      </div>

      <figure
        className={`img-wrapper ${
          get(slice, 'primary.hero_image_logo') === true ? 'logo-img' : ''
        }`}>
        <img
          className={`${
            get(slice, 'primary.hero_image_logo') === true ? 'contain-img' : 'cover-img'
          }`}
          alt={get(slice, 'primary.image.alt')}
          src={isMobile ? get(slice, 'primary.image.Mobile.url') : get(slice, 'primary.image.url')}
        />
      </figure>
    </section>
  );
};
export default HeroHalfImgContent;
