import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import PrismicRichText from '../components/PrismicRichText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import StrainByID from '../components/StrainByID';
import { renderSmell, renderTaste } from '../helpers/renderTerpenesTasteSmell';
import HorizontalSlider from '../components/HorizontalSlider';
import classNames from 'classnames';

export default function TerpeneFinderSlice({ slice }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('600'));

  const [terpenes, setTerpenes] = useState(false);
  const [terpeneCategories, setTerpeneCategories] = useState(false);

  const [selectedTaste, setSelectedTaste] = useState('I taste');
  const [selectedSmell, setSelectedSmell] = useState('I smell');

  const [terpeneMatch, setTerpeneMatch] = useState(false);

  const [disabled, setDisabled] = useState({ tastes: [], smells: [] });

  const loading = useSelector(state => get(state, 'prismic.terpenes_list.loading', true));
  const data = useSelector(state => get(state, 'prismic.terpenes_list.data', false));

  useEffect(() => {
    dispatch({
      type: 'PRISMIC_LOAD_REPEATABLE',
      key: 'terpene',
      reduxKey: 'terpenes_list'
    });
  }, [dispatch]);

  useEffect(() => {
    if (!loading && data) {
      //remove cymene for now
      const filteredData = data.filter(el => {
        return get(el, 'data.terpene_name[0].text') !== 'Cymene';
      });
      setTerpenes(filteredData);
    }
  }, [data, loading]);

  useEffect(() => {
    if (terpenes) {
      const smells = [];
      const tastes = [];
      terpenes.map(terpene => {
        smells.push(get(terpene, 'data.smell'));
        tastes.push(get(terpene, 'data.taste'));
        return null;
      });

      const uniqueSmells = [...new Set(smells)];
      const uniqueTastes = [...new Set(tastes)];

      setTerpeneCategories({ uniqueSmells, uniqueTastes });
    }
  }, [terpenes]);

  useEffect(() => {
    if (terpenes) {
      const terpeneMatch = terpenes.filter(
        el => el.data.smell === selectedSmell && el.data.taste === selectedTaste
      );
      if (terpeneMatch.length > 0) {
        setTerpeneMatch(terpeneMatch[0]);
      } else {
        setTerpeneMatch(false);
      }
    }
  }, [selectedSmell, selectedTaste, terpenes]);

  useEffect(() => {
    if (terpenes && selectedSmell !== 'I smell') {
      const disabledTastes = [];
      const enabledTastes = [];
      terpenes.map(el => {
        if (el.data.smell !== selectedSmell) {
          disabledTastes.push(get(el, 'data.taste'));
        } else {
          enabledTastes.push(get(el, 'data.taste'));
        }
        return null;
      });
      const finalExcludedTastes = disabledTastes.filter(n => !enabledTastes.includes(n));
      setDisabled({ ...disabled, tastes: finalExcludedTastes });
    }
    if (terpenes && selectedSmell === 'I smell') {
      setDisabled({ ...disabled, tastes: [] });
    }
  }, [selectedSmell, terpenes]);

  useEffect(() => {
    if (terpenes && selectedTaste !== 'I taste') {
      const disabledSmells = [];
      const enabledSmells = [];
      terpenes.map(el => {
        if (el.data.taste !== selectedTaste) {
          disabledSmells.push(get(el, 'data.smell'));
        } else {
          enabledSmells.push(get(el, 'data.smell'));
        }
        return null;
      });

      const finalExcludedSmells = disabledSmells.filter(n => !enabledSmells.includes(n));
      setDisabled({ ...disabled, smells: finalExcludedSmells });
    }

    if (terpenes && selectedTaste === 'I taste') {
      setDisabled({ ...disabled, smells: [] });
    }
  }, [selectedTaste, terpenes]);

  const handleSmellChange = event => {
    setSelectedSmell(event.target.value || event.target.getAttribute('value'));
  };

  const handleTasteChange = event => {
    setSelectedTaste(event.target.value || event.target.getAttribute('value'));
  };

  const reset = () => {
    setSelectedTaste('I taste');
    setSelectedSmell('I smell');
    setDisabled({ tastes: [], smells: [] });
  };

  const isNerolidol = get(terpeneMatch, 'data.terpene_name[0].text') === 'Nerolidol';

  return (
    <div className="terpene-finder-slice dark-flower">
      <div className="background-wrapper padding">
        <div className="content">
          <div className="content-wrapper">
            <PrismicRichText render={slice.primary.content} />
          </div>
        </div>
        <div className="wrapper">
          {terpeneCategories && (
            <div className="filters">
              <div className="filter-item filter-smell">
                <p className="descriptor">I like the smell of...</p>
                <div className="btn-wrapper">
                  {terpeneCategories.uniqueSmells.map((smell, i) => (
                    <button
                      value={selectedSmell}
                      onClick={handleSmellChange}
                      disabled={disabled.smells.includes(smell)}
                      key={i}
                      className={classNames(
                        'smell-btn',
                        disabled.smells.includes(smell) && 'disabled'
                      )}>
                      <img
                        src={renderSmell(smell, selectedSmell === smell)}
                        type="button"
                        onClick={handleSmellChange}
                        value={smell}
                        disabled={disabled.smells.includes(smell)}
                      />
                      {smell}
                    </button>
                  ))}
                </div>
              </div>

              <div className="filter-item">
                <p className="descriptor">I like the taste of...</p>
                <div className="btn-wrapper">
                  {terpeneCategories.uniqueTastes.map((taste, i) => (
                    <button
                      value={selectedTaste}
                      onClick={handleTasteChange}
                      disabled={disabled.tastes.includes(taste)}
                      key={i}
                      className={classNames(
                        'taste-btn',
                        disabled.tastes.includes(taste) && 'disabled'
                      )}>
                      <img
                        src={renderTaste(taste, selectedTaste === taste)}
                        type="button"
                        onClick={handleTasteChange}
                        value={taste}
                        disabled={disabled.tastes.includes(taste)}
                      />
                      {taste}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}
          {(selectedTaste !== 'I taste' || selectedSmell !== 'I smell') && (
            <div className="button-container center text-center">
              <button onClick={reset} className="outlined-button scale-text">
                <p>Reset</p>
              </button>
            </div>
          )}
          {get(terpeneMatch, 'data.terpene_name[0].text') && (
            <div className="selected-terpene">
              <h2 className="terpene-icon">
                {get(terpeneMatch, 'data.terpene_name[0].text') || ''}
              </h2>
            </div>
          )}

          <div className="recommended-strains">
            <p className="recommended-strain-copy poppins-semibold">Recommended Strains</p>
            {terpeneMatch && !isNerolidol && isMobile ? (
              <HorizontalSlider scrollContainerClassName="scroller finder-results-scroller">
                {terpeneMatch &&
                  terpeneMatch.data.strains.map((strain, i) => (
                    <StrainByID key={i} id={strain.strain.uid} />
                  ))}
              </HorizontalSlider>
            ) : terpeneMatch && !isNerolidol && !isMobile ? (
              <div className="resp-grid gap">
                {terpeneMatch &&
                  terpeneMatch.data.strains.map((strain, i) => (
                    <StrainByID key={i} id={strain.strain.uid} />
                  ))}
              </div>
            ) : !isNerolidol ? (
              <div className="strain-placeholder flex-center">
                <div className="placeholder-wrapper poppins-container">
                  <p className="question border flex-center">?</p>
                  <p className="copy flex-center uppercase">Your Strain</p>
                </div>
              </div>
            ) : (
              <div className="gg-4">
                <StrainByID id="gg-4" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
