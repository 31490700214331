import React from 'react';
import { get } from 'lodash';
import PrismicRichText from '../components/PrismicRichText';

export default function YoutubeSlice({ slice }) {
  const copy = get(slice, 'primary.copy');
  const videoID = get(slice, 'primary.youtube_video_id[0].text');

  return (
    <section className="youtube-video-embed cool-margin">
      <div className="container">
        <div className="copy">{<PrismicRichText render={copy} />}</div>
        <div className="video-embed-container">
          <iframe
            id="ytplayer"
            type="text/html"
            src={`https://www.youtube.com/embed/${videoID}`}
            frameborder="0"
            allowfullscreen
            title="Youtube Player"
          />
        </div>
      </div>
    </section>
  );
}
