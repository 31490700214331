import Prismic from 'prismic-javascript';
// -- Prismic API endpoint
const apiEndpoint = 'https://cresco-cannabis-staging.cdn.prismic.io/api/v2';
// -- Access Token if the repository is not public
const accessToken =
  'MC5ZQ1VfUkJJQUFDSUFUSWh4.77-977-9cO-_vT3vv73vv73vv70jHe-_vS0O77-977-9Fe-_vXHvv71cVgjvv71H77-977-977-977-9Enjvv73vv70';
// -- Prismic Client
const PrismicClient = Prismic.client(apiEndpoint, { accessToken });

export default PrismicClient;
