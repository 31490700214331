import React, { useState } from 'react';
import { PrismicRichText } from '@prismicio/react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import renderButtons from '../helpers/renderButtons';
import { Tween } from 'react-gsap';
import { get } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const VideoHero = ({slice}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('420'));
  const isTablet = useMediaQuery(theme.breakpoints.between('420', '768'));
  const isDesktop = useMediaQuery(theme.breakpoints.between('768', '1280'));

  const [showVideo, setShowVideo] = useState(false);

  const getCorrectBackground = background => {
    if (!background) {
      return false;
    }
    if (isMobile) {
      return background.Mobile;
    }
    if (isTablet) {
      return background.Tablet;
    }
    if (isDesktop) {
      return background.Desktop;
    } else {
      return background;
    }
  };

  const backgroundImage = getCorrectBackground(slice.primary.background_image);

  const backgroundImageStyle = {
    backgroundImage: 'url(' + backgroundImage.url + ')'
  };

  const backgroundColorStyle = {
    background: slice.primary.background_color
  };

  const videoID = get(slice.primary.youtube_video_id, '[0].text');

  const playVideo = e => {
    e.preventDefault();
    setShowVideo(true);
  };

  const closeVideo = e => {
    e.preventDefault();
    setShowVideo(false);
  };

  const buttons = [
    {
      active: true,
      cta_link: {
        url: '#'
      },
      cta_text: 'Play Trailer',
      button_variant: 'contained',
      button_color: 'primary',
      onclick: playVideo
    }
  ];

  const prismicButtons = slice.items;

  const finalButtons = buttons.concat(prismicButtons);

  return (
    <section className={`hero-section cool-margin ${showVideo && 'hero-section-video-embed'}`}>
      {showVideo ? (
        <>
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${videoID}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
          <IconButton
            onClick={closeVideo}
            className="hero-section-video-close"
            edge="end"
            aria-label="close video">
            <CloseIcon fontSize="large" htmlColor="#ffffff" />
          </IconButton>
          <div className="background-color" style={backgroundColorStyle}></div>
        </>
      ) : (
        <>
          <div className="wrapper">
            <div className="text-content">
              <Tween
                from={{ y: '-50', opacity: '0', scale: '1.05' }}
                stagger={0.3}
                duration={1}
                ease="back.out">
                <div>
                  <PrismicRichText field={slice.primary.content} />
                </div>
                <div className="button-container">{renderButtons(finalButtons)}</div>
              </Tween>
            </div>
          </div>
          <Tween from={{ x: '100', opacity: '0', scale: '1.2' }} duration={1} ease="circle.out">
            <div className="background-image" style={backgroundImageStyle}></div>
          </Tween>
          <div className="background-color" style={backgroundColorStyle}></div>
        </>
      )}
    </section>
  );
};

export default VideoHero;
