import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { PrismicRichText } from '@prismicio/react';
import Link from '../components/Link';
import moment from 'moment';

const BlogDetailsPage = props => {
  const handleLoading = props.loading;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('768'));
  const dispatch = useDispatch();
  const pageLoading = useSelector(state => get(state, 'prismic.blog_entry.loading', true));
  const entryData = useSelector(state => get(state, 'prismic.blog_entry.data', false));
  const pageTitle = get(entryData, 'page_title[0].text');
  const heroCopy = get(entryData, 'blog_entry_hero_copy');
  const copy = get(entryData, 'copy');
  const heroImage = isMobile
    ? get(entryData, 'hero_image.Mobile.url')
    : get(entryData, 'hero_image.url');
  const date = get(entryData, 'published_date');

  const whichPage = path => {
    if (path === '/404') {
      return '404';
    } else {
      return path.split('/')[2];
    }
  };

  const pagePath = whichPage(location.pathname);

  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    dispatch({
      type: 'PRISMIC_LOAD_SINGLE_BY_UID',
      key: 'blog_entry',
      id: pagePath
    });
  }, [dispatch, pagePath]);

  useEffect(() => {
    if (!pageLoading) {
      handleLoading(false);
    }
  }, [pageLoading, handleLoading]);

  if (!entryData) {
    return null;
  }

  return (
    !pageLoading &&
    entryData && (
      <>
        <section className="half-img-copy blog-details">
          <Link className="back-button" to="/the-grow">
            &#60; BACK
          </Link>
          <div className="copy">
            <div className="copy-wrapper ">
              <span className="mb-15 date block">{moment(date).format('MM/DD/YYYY')}</span>
              <h1>{pageTitle}</h1>
              <PrismicRichText field={heroCopy} />
            </div>
          </div>

          <figure>
            <img className="cover-img" alt={get(entryData, 'primary.image.alt')} src={heroImage} />
          </figure>
        </section>

        <section className="blog-details-copy">
          <div className="wrapper">
            <PrismicRichText field={copy} />
          </div>

          <div className="scroll-top-wrapper">
            <button
              onClick={scrollToTop}
              className="back-btn"
              aria-label="back to beginning of article">
              BACK TO TOP
            </button>
          </div>
        </section>
      </>
    )
  );
};

export default BlogDetailsPage;
