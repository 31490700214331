import React from 'react';
import { useDispatch } from 'react-redux';

const CookingSettingsLink = () => {
  const dispatch = useDispatch();

  const showCookieSettings = e => {
    e.preventDefault();
    dispatch({ type: 'COOKIE_COMPLIANCE/SHOW_DETAIL' });
  };

  return (
    <a style={{ color: '#87c7f7' }} href="#" onClick={showCookieSettings}>
      Cookie Settings
    </a>
  );
};

export default CookingSettingsLink;
