import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

const StlButton = styled(Button)`
  background: none !important;
  color: #87c7f7 !important;
  font-size: 1.4rem !important;
  font-family: Poppins-Semibold;
  padding: 0;
  margin: 0;
  &:hover {
    background: none;
    text-decoration: underline;
  }

  @media only screen and (max-width: 1050px) {
    font-size: 1.2rem;
  }
`;

const RenderShopButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <li className="underline">
      <StlButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="contained"
        color="primary"
        size="small"
        disableElevation>
        Shop
      </StlButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={handleClose}>
          <Link to="/shop" style={{ color: '#87c7f7', textDecoration: 'none' }}>
            Buy Online
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to="/store-locator" style={{ color: '#87c7f7', textDecoration: 'none' }}>
            Store Locator
          </Link>
        </MenuItem>
      </Menu>
    </li>
  );
};

export default RenderShopButton;
