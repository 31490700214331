import React from 'react';
import { get } from 'lodash';
import PrismicRichText from '../components/PrismicRichText';
import LazyLoadingImage from '../components/LazyLoadImage';
import classNames from 'classnames';
import { useTheme } from '@material-ui/core/styles';
import { styled } from 'styled-components';
import renderButtons from '../helpers/renderButtonsV2';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const StlImageWrapper = styled.div`
  width: 100%;
  height: 100%;

  &.mobileImageFirst {
    order: -1;
  }
  @media only screen and (min-width: 900px) {
    margin-top: 40px;
    &.mobileImageFirst {
      order: 0;
    }
  }
`;

const StlWrapper = styled.div`

  margin: auto;
  display: flex;
  flex-direction: column;

  .center {
    text-align-center;
  }

  .left {
    text-align: left;
  }

  @media only screen and (min-width: 900px) {
    figure {
      width: 700px;
      margin: auto;
    }
    }
`;

const ImageWithContent = ({ slice }) => {
  const theme = useTheme();
  const background = get(slice, 'primary.background_style');
  const showBackground = background === 'Dark Flower' ? 'dark-flower' : 'dark-blue-splatter';
  const leadWithMobile = get(slice, 'primary.lead_with_image_mobile');
  const mobileTextAlignment = get(slice, 'primary.mobile_text_alignment');
  const desktopTextAlignment = get(slice, 'primary.desktop_text_alignment');
  const buttons = get(slice, 'items');
  const isMobile = useMediaQuery(theme.breakpoints.down('900'));
  const isDesktop = !isMobile;
  const mobileImage = get(slice, 'primary.image.Mobile.url');
  const desktopImage = get(slice, 'primary.image.url');

  return (
    <div className={showBackground}>
      <StlWrapper className={classNames('background-wrapper', isDesktop && 'padding')}>
        <div
          className={classNames(
            'standard-content-section',
            isMobile && 'padding',
            isMobile && mobileTextAlignment,
            isDesktop && desktopTextAlignment
          )}>
          <PrismicRichText render={get(slice, 'primary.content')} />
          {isMobile && !isDesktop && (
            <div className="button-container center">{renderButtons(buttons)}</div>
          )}
        </div>
        {(mobileImage || desktopImage) && (
          <StlImageWrapper className={classNames('relative', leadWithMobile && 'mobileImageFirst')}>
            <figure className="contain-img">
              <LazyLoadingImage
                wrapperClassName={!isMobile && 'border'}
                src={isMobile ? mobileImage : desktopImage}
                alt={
                  isMobile
                    ? get(slice, 'primary.image.Mobile.alt')
                    : get(slice, 'primary.image.alt')
                }
              />
            </figure>
          </StlImageWrapper>
        )}
        {isDesktop && !isMobile && (
          <div className="button-container center">{renderButtons(buttons)}</div>
        )}
      </StlWrapper>
    </div>
  );
};

export default ImageWithContent;
