import React, { useEffect } from 'react';
import ShopPageDialog from '../components/Dialog/ShopPageDialog';

export default function PrismicPage(props) {
  const handleLoading = props.loading;

  useEffect(() => {
    handleLoading(false);
  }, [handleLoading]);

  return <ShopPageDialog />;
}
