import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLocation } from 'react-router-dom';
import Slices from '../slices';
import { Redirect } from 'react-router-dom';
import Loader from '../components/Loader';

export default function ProductsPage(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const handleLoading = props.loading;

  const whichPage = path => {
    if (path === '/404') {
      return '404';
    } else {
      return path.split('/')[2];
    }
  };

  const pagePath = whichPage(location.pathname);

  const pageLoading = useSelector(state => get(state, 'prismic.products.loading', true));
  const page = useSelector(state => get(state, 'prismic.products.data', false));
  const showGlobalSlices = get(page, 'global_slices');

  const globalSlicesLoading = useSelector(state =>
    get(state, 'prismic.global_slices.loading', true)
  );
  const globalSlices = useSelector(state => get(state, 'prismic.global_slices.data.body', false));

  const slices = get(page, 'body');

  useEffect(() => {
    dispatch({
      type: 'PRISMIC_LOAD_SINGLE_BY_UID',
      key: 'products',
      id: pagePath
    });
    dispatch({
      type: 'PRISMIC_LOAD_SINGLE_PAGE',
      key: 'global_slices'
    });
  }, [dispatch, pagePath]);

  useEffect(() => {
    if (!pageLoading) {
      handleLoading(false);
    }
  }, [pageLoading, handleLoading]);

  if (!page && !pageLoading) {
    return <Redirect to={'/404'} />;
  }

  if (pageLoading) {
    return (
      <div className="loading-screen">
        <Loader $minHeight="800px" />
      </div>
    );
  }

  return (
    !pageLoading &&
    page && (
      <div className={`${pagePath}-page page`}>
        <Slices slices={slices}></Slices>
        {showGlobalSlices && !globalSlicesLoading && globalSlices && (
          <div className="cool-margin">
            <Slices slices={globalSlices}></Slices>
          </div>
        )}
      </div>
    )
  );
}
