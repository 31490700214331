import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname === '/strains' &&
      prevProps.location.pathname.includes('strain')
    ) {
      return null;
    } else if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  // componentDidMount() {
  //   const elements = document.getElementsByClassName('custom-animation');

  //   const hasAnimatedElements = elements.length > 0;

  //   const animateElements = elements => {
  //     for (let el of elements) {
  //       if (el.offsetTop - window.outerHeight * 0.75 < window.pageYOffset) {
  //         el.classList.add('animation-active');
  //       }
  //       if (el.offsetTop + el.clientHeight - window.outerHeight < window.pageYOffset) {
  //         el.classList.add('scrolled-to-bottom');
  //       }
  //     }
  //   };

  //   if (hasAnimatedElements) {
  //     animateElements(elements);
  //     window.onscroll = () => {
  //       animateElements(elements);
  //     };
  //   }
  // }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
