import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import cx from 'classnames';
import styled from 'styled-components';

const StlButton = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 0;
  background: none;
  border: none;
`;

export default function CloseButton({ onClick, className, onKeyDown, ...rest }) {
  return (
    <StlButton
      data-cy="CloseButton"
      aria-label="close"
      className={cx('cursor-pointer gray opacity-hover', className)}
      onClick={onClick}
      onKeyDown={onKeyDown}
      {...rest}>
      <CloseIcon />
    </StlButton>
  );
}
