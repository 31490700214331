import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const CarouselV2 = ({ children, className }) => {
  const theme = useTheme();
  const xsViewport = useMediaQuery(theme.breakpoints.down('372'));
  const slideWidth = xsViewport ? '58%' : '38%';
  return (
    <div style={{ width: '100%', overflow: 'hidden' }}>
      <Carousel
        className={className}
        showThumbs={true}
        showStatus={false}
        swipeable={false}
        showIndicators={true}
        width={slideWidth}>
        {children}
      </Carousel>
    </div>
  );
};

export default CarouselV2;
