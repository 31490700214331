import { put } from "redux-saga/effects";

export function* initialAppLoad(api, router) {
  yield put({
    type: "PRISMIC_LOAD_SINGLE_PAGE",
    key: "header",
  });

  yield put({
    type: "PRISMIC_LOAD_SINGLE_PAGE",
    key: "footer",
  });

  yield put({ type: "COOKIE_COMPLIANCE/CHECK" });
}
