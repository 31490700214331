import React from 'react';
import { styled } from 'styled-components';
import classNames from 'classnames';

const StlWrapper = styled.div`
  width: 100%;
  min-height: ${props => props.$minHeight || '500px'};
`;

const Loader = ({ className, $minHeight }) => {
  return (
    <StlWrapper $minHeight={$minHeight} className="relative">
      <div className={classNames(className, 'ring')}>
        <div className="text">Loading</div>
        <span></span>
      </div>
    </StlWrapper>
  );
};

export default Loader;
