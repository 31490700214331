import React, { useState, useEffect } from 'react';
import { Button, TextField } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { ReactComponent as FAQIcon } from './../../imagesV2/icons/ico-question.svg';
import FormControl from '@material-ui/core/FormControl';
import PrismicRichText from '../PrismicRichText';
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';

export default function FAQButton({ activePage }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState('');
  const [faqs, setFAQs] = useState(false);
  const [error, setError] = useState(false);

  const path = activePage;

  const loading = useSelector(state => get(state, 'prismic.faqs.loading', true));
  const data = useSelector(state => get(state, 'prismic.faqs.data', false));

  const handleChange = event => {
    setSearch(event.target.value);

    const filteredData = data.faqs.filter(el =>
      el.question[0].text.toLowerCase().includes(event.target.value)
    );

    if (filteredData && filteredData.length > 0) {
      setFAQs(filteredData);
      setError(false);
    } else {
      setFAQs(false);
      setError(true);
    }
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch({
      type: 'PRISMIC_LOAD_SINGLE_PAGE',
      key: 'faqs'
    });
  }, [dispatch]);

  useEffect(() => {
    setError(false);
    setAnchorEl(null);
    if (data && !loading) {
      const filteredData = data.faqs.filter(el => el.feature);
      setFAQs(filteredData);
    }
  }, [data, loading]);

  if (!loading && !data) {
    return null;
  }

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow onClick={() => setOpen(!open)}>
          <TableCell>
            <Box className="question" margin={1}>
              <PrismicRichText render={row.question} />
            </Box>
          </TableCell>
          <TableCell width={10}>
            <IconButton aria-label="expand row" size="small">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={2}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box className="answer" margin={1}>
                <PrismicRichText render={row.answer} />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const renderFAQs = items => {
    return items.map((el, i) => <Row key={i} row={el} />);
  };

  return (
    !loading &&
    data &&
    path !== '/faq' && (
      <div className="floating-faq">
        <Button onClick={handleClick} color="secondary" variant="contained" disableElevation>
          <FAQIcon className="mr-5" />
          FAQs
        </Button>

        <Popover
          className="faq-popover"
          anchorReference="anchorPosition"
          anchorPosition={{ bottom: 0, right: 0, left: 'auto', top: 'auto' }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          <div className="faq-module-wrapper">
            <div className="input-wrapper">
              <FormControl>
                <TextField
                  id="search-faqs"
                  name="search-faqs"
                  value={search}
                  onChange={handleChange}
                  label="Search FAQs"
                  variant="outlined"
                  size="large"
                  color="primary"></TextField>
              </FormControl>
            </div>
            {faqs && (
              <TableContainer>
                <Table aria-label="collapsible table">
                  <TableBody>{renderFAQs(faqs)}</TableBody>
                </Table>
              </TableContainer>
            )}
            {error && (
              <p className="error">
                We can't find what you're looking for. <br></br>Please visit the{' '}
                <Link to="/faq">FAQ Page</Link> to see all FAQs.
              </p>
            )}
          </div>
        </Popover>
      </div>
    )
  );
}
