import React from 'react';
import PrismicRichText from '../components/PrismicRichText';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import renderButtons from './../helpers/renderButtons';
import { get } from 'lodash';

export default function TextBackgroundSlice({ slice }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('768'));
  const backgroundImage = isMobile
    ? slice.primary.mobile_background_image.url
    : slice.primary.desktop_background_image.url;

  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    color: slice.primary.text_color,
    backgroundColor: slice.primary.background_color
  };

  const disclaimerText = get(slice, 'primary.disclaimer[0].text', false);

  return (
    <section
      className={`text-background-slice section-padding cool-margin ${
        slice.primary.half_width && !isMobile && 'half-width'
      }`}
      style={backgroundStyle}>
      <div
        className={`wrapper ${
          !slice.primary.half_width &&
          slice.primary.content_alignment &&
          'align-' + slice.primary.content_alignment
        } ${
          slice.primary.mobile_content_alignment &&
          'align-' + slice.primary.mobile_content_alignment
        }`}>
        <div className="text-content">
          <PrismicRichText render={slice.primary.content} />

          {slice.primary.foreground_image.url && (
            <img
              className="foreground-image"
              src={slice.primary.foreground_image.url}
              alt="Cresco Cannabis"
            />
          )}
          {disclaimerText && (
            <div className="really-small">
              <PrismicRichText render={slice.primary.disclaimer} />
            </div>
          )}

          <div className="button-container">{renderButtons(slice.items)}</div>
        </div>
      </div>
    </section>
  );
}
