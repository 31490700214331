import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import Cookie from 'js-cookie';
import Routes from './routes';
import RenderMetaTags from './components/SEO';
import AgeGate from './components/AgeGate';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import FAQButton from './components/FAQButton';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './theme';
import Loader from './components/Loader';
import Dialogs from './components/Dialog';

import './cssV2/styles.css';

var pjson = require('../package.json');

console.log(`Version: ${pjson.version}`);

export default function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  const ignoreAgeGate =
    location.pathname === '/privacy-policy' || location.pathname === '/terms-of-service';

  const disableFAQButton = ['/socialjustice', '/michaelthompson', '/store-locator'].find(
    el => el === location.pathname
  );

  const isStaging =
    window.location.hostname.includes('localhost') ||
    window.location.hostname.includes('crescolabsdev');

  const over21cookie = Cookie.get('cresco_over21') || false;
  const [ageVerified, setAgeVerified] = useState(over21cookie);

  const [loading, setLoading] = useState(true);

  const enableAnalytics = useSelector(state => get(state, 'cookieCompliance.analytics', false));

  const handleLoading = value => {
    setLoading(value);
  };

  useEffect(() => {
    if (location.pathname === '/store-locator') {
      handleLoading(false);
    }
  }, [location.pathname]);

  const verifyAge = () => {
    setAgeVerified(true);
  };

  useEffect(() => {
    dispatch({
      type: 'INITIALIZE_APP'
    });
    dispatch({
      type: 'ACCESSIBILITY/CHECK'
    });
  }, [dispatch]);

  useEffect(() => {
    if (ignoreAgeGate) {
      setAgeVerified(ignoreAgeGate);
    } else {
      setAgeVerified(over21cookie);
    }
  }, [ignoreAgeGate, over21cookie]);

  useEffect(() => {
    if (enableAnalytics) {
      window.analytics.page();
    }
  }, [location.pathname, enableAnalytics]);

  return (
    <ThemeProvider theme={theme}>
      <RenderMetaTags pathname={location.pathname} />
      <ScrollToTop>
        <Dialogs />
        {!ageVerified && <AgeGate verifyAge={verifyAge} />}
        {loading && (
          <div className="loading-screen">
            <Loader $minHeight="800px" />
          </div>
        )}

        <Header activePage={location.pathname} />

        <div id="app-body">
          <Routes loading={handleLoading} isStaging={isStaging} />
        </div>

        {!disableFAQButton && <FAQButton activePage={location.pathname} />}

        <Footer />
      </ScrollToTop>
    </ThemeProvider>
  );
}
