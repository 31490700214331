import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, orderBy, map, filter, isEmpty } from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Cookie from 'js-cookie';
import StrainCard from '../components/StrainCard';
import StateDropdown from '../components/StateDropdown';
import HorizontalSlider from '../components/HorizontalSlider';
import { styled } from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Loader from '../components/Loader';

const StlSelectWrapper = styled.div`
  display: inline-flex;
  gap: 30px;
  justify-content: center;
  margin: auto;
`;

const ShowFeaturedStrains = ({ featuredStrains }) => {
  const theme = useTheme();
  const featuredBreakpoint = useMediaQuery(theme.breakpoints.down('950'));
  if (isEmpty(featuredStrains)) return null;

  return (
    <div className="dark-blue-splatter">
      <div className="background-wrapper padding-tb text-center max-width">
        <h2 className="resp-mobile-bottom">Featured Strains</h2>
        {featuredBreakpoint ? (
          <HorizontalSlider scrollContainerClassName="scroller">
            {map(featuredStrains, (el, i) => (
              <StrainCard
                key={i}
                strainID={get(el, 'uid')}
                strainName={get(el, 'data.name[0].text')}
                strainImage={get(el, 'data.image.url')}
                className="mr-10 strain-card"
              />
            ))}
          </HorizontalSlider>
        ) : (
          <div className="resp-grid">
            {map(featuredStrains, (el, i) => (
              <StrainCard
                key={i}
                strainID={get(el, 'uid')}
                strainName={get(el, 'data.name[0].text')}
                strainImage={get(el, 'data.image.url')}
                className="mr-10 strain-card"
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default function StrainFinderSlice({ slice }) {
  const dispatch = useDispatch();

  const [strains, setStrains] = useState(false);
  const [strainType, setStrainType] = useState(Cookie.get('strain_type') || 'All');
  const [strainState, setStrainState] = useState(Cookie.get('strain_state') || 'All');
  const [featuredStrains, setFeaturedStrains] = useState(false);

  const [justViewed, setJustviewed] = useState(Cookie.get('just_viewed') || '');
  const [states, setStates] = useState([]);

  const loading = useSelector(state => get(state, 'prismic.strains_list.loading'));
  const data = useSelector(state => get(state, 'prismic.strains_list.data', false));

  useEffect(() => {
    dispatch({
      type: 'PRISMIC_LOAD_REPEATABLE',
      key: 'strains',
      reduxKey: 'strains_list',
      useCache: true
    });
  }, [dispatch]);

  useEffect(() => {
    if (!loading && data) {
      const listTags = map(data, 'tags');
      let sortedData = orderBy(data, 'data.name[0].text', 'asc');
      const filterFeatured = filter(sortedData, el => {
        return el.tags.includes('featured');
      });

      const uniqueTags = [...new Set(listTags.flat())].filter(e => e !== 'featured');
      setStrains(sortedData);
      setStates(uniqueTags);
      setFeaturedStrains(filterFeatured);
    }
  }, [data, loading]);

  useEffect(() => {
    const sortedData = orderBy(data, 'data.name[0].text', 'asc');

    const strainTypeAndStateFilter = el => {
      if (strainType === 'All' && strainState === 'All') return true;
      if (strainType === 'New Strain' && strainState === 'All') return el.data.new_strain === true;
      if (strainType === 'All' && strainState !== 'All') return el.tags.includes(strainState);
      if (strainType !== 'All' && strainType !== 'New Strain' && strainState === 'All')
        return el.data.type === strainType;

      return el.data.type === strainType && el.tags.includes(strainState);
    };

    const filteredByTypeAndState = sortedData.filter(strainTypeAndStateFilter);

    setStrains(filteredByTypeAndState);
  }, [data, loading, strainType, strainState]);

  const handleChange = event => {
    setStrainType(event.target.value);
    Cookie.set('strain_type', event.target.value, { expires: 1 });
  };

  const handleStateChange = event => {
    setStrainState(event.target.value);
    Cookie.set('strain_state', event.target.value, { expires: 1 });
  };

  if (!strains) {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    strains && (
      <div>
        <ShowFeaturedStrains featuredStrains={featuredStrains} />
        <div className="dark-flower background-repeat">
          <div className="background-wrapper padding-less text-center max-width">
            <h2 className="resp-mobile-bottom">Strain Library</h2>
            <StlSelectWrapper className="button-container select-fields no-margin">
              <FormControl>
                <TextField
                  id="select-strain-type"
                  select
                  name="select-strain"
                  value={strainType}
                  onChange={handleChange}
                  label="Strain Type"
                  variant="outlined"
                  color="primary">
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="Sativa">Sativa</MenuItem>
                  <MenuItem value="Hybrid">Hybrid</MenuItem>
                  <MenuItem value="Indica">Indica</MenuItem>
                  <MenuItem value="New Strain">New Strains</MenuItem>
                </TextField>
              </FormControl>

              <StateDropdown options={states} value={strainState} onChange={handleStateChange} />
            </StlSelectWrapper>
            <div className="flex-center">
              <div className="strains-list max-width">
                {strains.map((strain, i) => (
                  <StrainCard
                    strainType={strainType}
                    key={i}
                    strainID={strain.uid}
                    strainName={strain.data.name[0].text}
                    strainImage={strain.data.image.url}
                    justViewed={justViewed}
                    cookie={Cookie}
                    newStrain={strain.data.new_strain}
                    className="single-strain"
                  />
                ))}
              </div>
            </div>
            {strains.length === 0 && (
              <h4 className="center-align">
                Sorry, no strains match your search. Please change your selection above.
              </h4>
            )}
          </div>
        </div>
      </div>
    )
  );
}
