import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, orderBy } from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { PrismicRichText } from '@prismicio/react';
import Link from './../components/Link';
import { Button } from '@material-ui/core';
import DatePicker from '../components/DatePicker';
import moment from 'moment';
import classNames from 'classnames';

const BlogSlice = ({slice}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('768'));
  const dispatch = useDispatch();
  const pageLoading = useSelector(state => get(state, 'prismic.blog_entries_list.loading', true));
  const data = useSelector(state => get(state, 'prismic.blog_entries_list.data', false));
  const [selectedMonthYear, setSelectedMonthYear] = useState(false);
  const [blogArticles, setBlogArticles] = useState(false);
  const [uniqueDates, setUniqeDates] = useState([]);
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);

  const handleChange = date => {
    setSelectedMonthYear(date);
  };

  const prevMonth = () => {
    if (hasPrev) {
      setSelectedMonthYear(hasPrev);
    }
  };

  const nextMonth = () => {
    if (hasNext) {
      setSelectedMonthYear(hasNext);
    }
  };

  useEffect(() => {
    dispatch({
      type: 'PRISMIC_LOAD_REPEATABLE',
      key: 'blog_entry',
      reduxKey: 'blog_entries_list'
    });
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      const sortedData = orderBy(data, ['data.published_date'], ['desc']);
      const latestMonthYear = moment(get(sortedData, '[0].data.published_date')).format(
        'MMMM, YYYY'
      );
      const allDates = [];
      sortedData.map(el => {
        const formatDate = moment(get(el, 'data.published_date')).format('MMMM, YYYY');
        allDates.push(formatDate);
      });
      setUniqeDates([...new Set(allDates)]);
      setBlogArticles(sortedData);
      setSelectedMonthYear(latestMonthYear);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const sortedData = orderBy(data, ['data.published_date'], ['desc']);
      const filteredArticles = sortedData.filter(
        el => moment(el.data.published_date).format('MMMM, YYYY') === selectedMonthYear
      );

      setBlogArticles(filteredArticles);
      const monthIndex = uniqueDates.findIndex(el => el === selectedMonthYear);
      const hasPrevMonth = uniqueDates[monthIndex + 1];
      const hasNextMonth = uniqueDates[monthIndex - 1];
      if (hasPrevMonth) {
        setHasPrev(hasPrevMonth);
      } else {
        setHasPrev(false);
      }
      if (hasNextMonth) {
        setHasNext(hasNextMonth);
      } else {
        setHasNext(false);
      }
    }
  }, [data, selectedMonthYear]);

  if (!blogArticles) {
    return null;
  }

  return (
    !pageLoading &&
    blogArticles && (
      <div className="blog-page">
        <div className="date-picker-slider">
          <div className="slider-arrow-wrapper">
            <button
              onClick={prevMonth}
              className={classNames('custom-carousel-btn btn-l', hasPrev && 'active-btn')}
              aria-label="Previous Slide"></button>

            <h3 className="mb-0">{selectedMonthYear}</h3>

            <button
              onClick={nextMonth}
              className={classNames('custom-carousel-btn btn-r', hasNext && 'active-btn')}
              aria-label="Next Slide"></button>
          </div>
          <DatePicker dates={uniqueDates} onClick={handleChange} />
        </div>

        <ul className="blog-wrapper" aria-label="All Blog Articles">
          {blogArticles.map((entry, i) => (
            <li key={i}>
              <Link to={'/the-grow/' + get(entry, 'uid')}>
                <figure>
                  <img
                    className="cover-img"
                    alt={get(entry, 'data.hero_image.alt')}
                    src={
                      isMobile
                        ? get(entry, 'data.hero_image.Mobile.url')
                        : get(entry, 'data.hero_image.url')
                    }
                  />
                </figure>
                <div className="overlay center-align">
                  <div className="animate-wrapper">
                    <span className="mb-15 date block">
                      {moment(get(entry, 'data.published_date')).format('MM/DD/YYYY')}
                    </span>
                    <h2>{get(entry, 'data.page_title[0].text')}</h2>
                    <PrismicRichText field={get(entry, 'data.excerpt')} />

                    <Button
                      variant="contained"
                      color="secondary"
                      aria-label={`Read more about ${get(entry, 'data.page_title[0].text')}`}
                      className="mt-15">
                      Read More
                    </Button>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )
  );
};

export default BlogSlice;
