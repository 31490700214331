import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { styled } from 'styled-components';
import { lighterBlue, lightBlue } from '../../constants/colors';

const StlFormControlLabel = styled(FormControlLabel)`
  display: flex;
  gap: 10px;
  .MuiSwitch-track {
    background: ${lighterBlue};
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background: ${lightBlue};
    margin-left: 20px;
  }

  .MuiTypography-body1 {
    color: ${lightBlue} !important;
    font-size: 1.3rem;
    font-family: 'Poppins-Bold';
  }
`;

export default function AccessibilitySwitch() {
  const dispatch = useDispatch();
  const handleChange = event => {
    dispatch({
      type: 'ACCESSIBILITY/SAVE',
      show: event.target.checked
    });
  };
  const showAccessibility = useSelector(state => get(state, 'accessibility.show', false));
  return (
    <StlFormControlLabel
      control={
        <Switch
          checked={showAccessibility}
          onChange={handleChange}
          name="accessibility-switch"
          inputProps={{ 'aria-label': 'Toggle Accessibility' }}
          color="secondary"
          className="mr-20 ml-20"
        />
      }
      label={
        showAccessibility ? 'Accessibility On' : ' Turn On Accessibility'
      }></StlFormControlLabel>
  );
}
