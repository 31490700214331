import React from 'react';
import { PrismicRichText } from '@prismicio/react';

export default function ColumnSlice({slice}) {
  const backgroundStyle = {
    background: slice.primary.background_color,
    color: slice.primary.text_color
  };

  const renderColumns = items => {
    return items.map((el, i) => (
      <div key={i} className="column box-padding center-align">
        <div className="column-image">
          <img src={el.image.url} alt={el.content[0].text + ' packaging'} />
        </div>
        <PrismicRichText field={el.content} />
      </div>
    ));
  };
  return (
    <section className="column-section cool-margin" style={backgroundStyle}>
      <div className="wrapper">
        <div className="text-content box-padding center-align">
          <PrismicRichText field={slice.primary.content} />
        </div>
        <div className="column-wrapper">{renderColumns(slice.items)}</div>
      </div>
    </section>
  );
}
