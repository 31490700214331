import React from 'react';
import PrismicPage from './pages/PrismicPage';
import ProductsPage from './pages/ProductsPage';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import StoreLocatorPage from './pages/StoreLocatorPage';
import ShopPage from './pages/ShopPage';
import StrainDetailPage from './pages/StrainDetailPage';
import BlogDetailsPage from './pages/BlogDetailsPage';

export default function Routes({ loading, isStaging }) {
  const { pathname } = useLocation();
  const prismicPage = () => {
    return <PrismicPage loading={loading} isStaging={isStaging} />;
  };

  const productsPage = () => {
    return <ProductsPage loading={loading} />;
  };

  const shopPage = () => {
    return <ShopPage loading={loading} />;
  };
  const strainDetailPage = () => {
    return <StrainDetailPage loading={loading} />;
  };

  const blogDetailsPage = () => {
    return <BlogDetailsPage loading={loading} />;
  };

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Route path="/liquid-live-resin">
        <Redirect to="/products/liquid-live-resin" />
      </Route>
      <Route exact path="/contact">
        <Redirect to={'/404'} />;
      </Route>
      <Route path="/social-justice" exact>
        <Redirect to="/socialjustice" />
      </Route>
      <Route path="/products/:id" render={productsPage} />
      <Route path="/strains/:id" render={strainDetailPage} />
      <Route path="/shop" render={shopPage} />
      <Route exact path="/store-locator" component={StoreLocatorPage} />
      <Route exact path="/the-grow/:id" component={blogDetailsPage} />
      <Route exact path="/the-grow" component={prismicPage} />
      <Route path="/:id" render={prismicPage} />
      <Route exact path="/" render={prismicPage} />
    </Switch>
  );
}
