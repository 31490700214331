import React from 'react';
import { get, map } from 'lodash';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import PrismicRichText from '../components/PrismicRichText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { SimpleLink } from '../helpers/renderButtonsV2';
import { styled } from 'styled-components';
import { lighterBlue } from '../constants/colors';

const StlCarousel = styled(Carousel)`
  .control-dots {
    bottom: 67px;
    display: flex;
    justify-content: center;

    .dot.selected {
      color: ${lighterBlue};
    }
    @media screen and (min-width: 900px) {
      bottom: 80px;
      .dot {
        width: 10px;
        height: 10px;
      }
    }
  }
  .control-arrow {
    display: none !important;
  }
`;

const StlWrapper = styled.div`
  text-align: center;
`;

const StlCopy2 = styled.div`
  margin-top: 25px;
  @media only screen and (min-width: 900px) {
    margin-top: 40px;
  }

  .button-container {
    margin: 66px auto 0;
    width: 203px;
    height: 45px;

    @media only screen and (min-width: 900px) {
      margin: 80px auto 0;
      width: 242px;
      height: 52px;
    }
  }
`;

const StlImage = styled.figure`
  max-width: 600px;
  margin: auto;
  img {
    border: 1px solid ${lighterBlue} !important;
  }
  @media screen and (min-width: 900px) {
    max-width: 700px;
  }
`;

const ContentSlider = ({ slice }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('900'));
  const background = get(slice, 'primary.background_style');
  const showBackground = background === 'Dark Flower' ? 'dark-flower' : 'dark-blue-splatter';
  const mainCopy = get(slice, 'primary.copy');

  return (
    <div className={showBackground}>
      <StlWrapper className="background-wrapper padding">
        {mainCopy && (
          <div>
            <PrismicRichText render={mainCopy} />
          </div>
        )}

        <StlCarousel showThumbs={false} showStatus={false} showIndicators={true}>
          {map(slice.items, (el, i) => {
            return (
              <section key={i}>
                {get(el, 'headline') && (
                  <div className="resp-mobile-bottom">
                    <PrismicRichText render={get(el, 'headline')} />
                  </div>
                )}

                <StlImage className="cover-img">
                  <img
                    src={isMobile ? get(el, 'image.Mobile.url') : get(el, 'image.url')}
                    alt={isMobile ? get(el, 'image.Mobile.alt') : get(el, 'image.alt')}
                  />
                </StlImage>

                <StlCopy2>
                  <PrismicRichText render={get(el, 'copy')} />
                  <div className="button-container">
                    <SimpleLink
                      key={i}
                      to={get(el, 'cta_link.uid')}
                      url={get(el, 'cta_link.url')}
                      target={get(el, 'cta_link.target')}
                      variant={el?.button_variant}
                      text={get(el, 'cta_text[0].text')}
                    />
                  </div>
                </StlCopy2>
              </section>
            );
          })}
        </StlCarousel>
      </StlWrapper>
    </div>
  );
};

export default ContentSlider;
