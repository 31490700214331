import React, { useState, useEffect } from 'react';
import { useAllPrismicDocumentsByType } from '@prismicio/react';
import { get, map } from 'lodash';
import PrismicRichText from '../components/PrismicRichText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CarouselV2 from '../components/Carousel/carouselV2';
import CarouselInfoSlide from '../components/CarouselInfoSlide';

export default function TerpenesFeatureSlice({ slice }) {
  const [data, { state }] = useAllPrismicDocumentsByType('terpene');
  const loading = state === 'loading';
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.down('650'));

  const [terpenes, setTerpenes] = useState(false);
  const [selectedTerpene, setSelectedTerpene] = useState(false);

  useEffect(() => {
    if (!loading && data) {
      const filteredTerpenes = data.filter(function (array_el) {
        return (
          slice.items.filter(function (anotherOne_el) {
            return anotherOne_el.terpene.id === array_el.id;
          }).length !== 0
        );
      });

      const sortList = [];

      slice.items.forEach(el => {
        sortList.push(el.terpene.id);
      });

      const sortedObj = [...filteredTerpenes].sort((a, b) => {
        return sortList.indexOf(get(a, 'id')) - sortList.indexOf(get(b, 'id'));
      });

      setTerpenes(sortedObj);
      setSelectedTerpene(sortedObj[0]);
    }
  }, [data, loading]);

  const handleChange = event => {
    const selected = event.target.value || event.target.getAttribute('value');
    setSelectedTerpene(
      terpenes.find(terpene => get(terpene, 'data.terpene_name[0].text') === selected)
    );
  };

  return (
    terpenes && (
      <div className="terpenes-feature-slice dark-blue-splatter">
        <div className="background-wrapper">
          <div className="content text-center">
            <PrismicRichText render={get(slice, 'primary.content')} />
          </div>

          {breakpoint ? (
            <CarouselV2 className="info-carousel terpene-info info-carousel-padding">
              {map(terpenes, (terpene, i) => {
                return (
                  <CarouselInfoSlide
                    key={i}
                    activeImage={get(terpene, 'data.primary_icon.url')}
                    inactiveImage={get(terpene, 'data.secondary_icon.url')}
                    carouselIconStyle={true}
                    copyClassName="strain-info-slider"
                    optionalCopy={get(terpene, 'data.terpene_name[0].text')}>
                    <PrismicRichText render={get(terpene, 'data.content')} />
                  </CarouselInfoSlide>
                );
              })}
            </CarouselV2>
          ) : (
            <div className="terpenes-wrapper">
              <div className="terpenes-trypich-section">
                <div className="terpenes-list">
                  {map(terpenes, (terpene, i) => {
                    const selectedTerpeneName = get(selectedTerpene, 'data.terpene_name[0].text');
                    const terpeneName = get(terpene, 'data.terpene_name[0].text');
                    const filledIcon = get(terpene, 'data.primary_icon.url');
                    const outlinedIcon = get(terpene, 'data.secondary_icon.url');
                    return (
                      <button
                        key={i}
                        onClick={handleChange}
                        value={get(terpene, 'data.terpene_name[0].text')}
                        className={`relative ${selectedTerpeneName === terpeneName && 'active'}`}>
                        <img
                          style={{ width: '70px' }}
                          src={selectedTerpeneName === terpeneName ? filledIcon : outlinedIcon}
                          type="button"
                          onClick={handleChange}
                          value={get(terpene, 'data.terpene_name[0].text')}
                        />
                        {get(terpene, 'data.terpene_name[0].text')}
                      </button>
                    );
                  })}
                </div>

                <div className="selected-terpene-img-section">
                  <figure className="contain-img height-auto">
                    <img
                      src={get(selectedTerpene, 'data.primary_icon.url')}
                      className="terpene-icon"
                      alt={get(selectedTerpene, 'data.terpene_name[0].text')}
                    />
                  </figure>
                  <h2 className="name text-center">
                    {get(selectedTerpene, 'data.terpene_name[0].text')}
                  </h2>
                </div>

                <div className="selected-terpene-content">
                  <figure className="contain-img terpene-img height-auto mx-auto">
                    <img
                      src={get(selectedTerpene, 'data.primary_icon.url')}
                      className="terpene-icon"
                      alt={get(selectedTerpene, 'data.terpene_name[0].text')}
                    />
                  </figure>
                  <h2 className="name"> {get(selectedTerpene, 'data.terpene_name[0].text')}</h2>
                  <PrismicRichText render={get(selectedTerpene, 'data.content')} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
}
