import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import renderButtons from './../helpers/renderButtons';

export default function PhotoSection({slice}) {
  const backgroundStyle = {
    backgroundColor: slice.primary.background_color,
    color: slice.primary.text_color
  };

  return (
    <section className="photo-section cool-margin" style={backgroundStyle}>
      <div className="wrapper">
        <div className={`flex-container ${slice.primary.lead_with_image && 'lead-image'}`}>
          <div className="flex-item photo">
            <img src={slice.primary.image.url} alt="Hero Element" />
          </div>
          <div className="flex-item">
            <div className="content box-padding">
              <div className="text-content">
                <PrismicRichText field={slice.primary.content} />
              </div>
              <div className="button-container">{renderButtons(slice.items)}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
