import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { InfoWindow } from 'google-maps-react';
import mapStyle from '../constants/mapStyle';
import Geocode from 'react-geocode';
import wholesaleCustomersNear from '../api';
import makeLowercaseKeys from '../helpers/lowercaseKeys';
import TextField from '@material-ui/core/TextField';
import mapMarker from '../imagesV2/icons/map-pin-outlined.png';
import websiteIcon from '../imagesV2/icons/ico-website.png';
import locationIcon from '../imagesV2/icons/ico-location.png';
import { styled } from 'styled-components';
const apiKey = 'AIzaSyBuLWEJhtMCAW-R9qP1l019HbheUR3DqRw';
const markerRefs = [];
import { SimpleLink } from '../helpers/renderButtons';
import Loader from '../components/Loader';
import { darkBlue } from '../constants/colors';
import ProviderWithRouter from '../ProviderWithRouter';
import { theme } from './../theme';
import { ThemeProvider } from '@material-ui/core/styles';
import Link from '../components/Link';

const StlButtonContainer = styled.form`
  max-width: 400px;
  margin: auto;
  .MuiInputBase-root {
    min-height: 57px !important;
    label {
      color: red !important;
    }
  }
`;

const StlInfoButtonContainer = styled.div`
  display: flex;
  gap: 7px;
  flex-direction: column;
  .MuiButtonBase-root.MuiButton-contained {
    min-width: 100px !important;
    font-family: 'Poppins-Semibold';
    font-size: 1.1rem;
  }
`;

const StlBackToTopLink = styled.a`
  font-size: 1.4rem;
  text-decoration: none;
  color: ${darkBlue};
`;

class StoreLocatorPage extends Component {
  state = {
    activeMarker: null,
    activeID: null,
    infoWindowVisible: false,
    initialCenter: {
      lat: 37.774929,
      lng: -122.419416
    },
    mapRef: null,
    stores: null,
    zip: null,
    enableGeolocation: false,
    infoMessage: false,
    page: 1,
    totalPages: false,
    brand: 'Cresco',
    searchLocation: 'Chicago, IL',
    limit: 10,
    offset: 0,
    loading: true,
    searchLocationCoords: false,
    zoom: 5,
    renderedOnce: false
  };

  getStores() {
    wholesaleCustomersNear(
      this.state.searchLocation,
      this.state.brand,
      this.state.limit,
      this.state.offset
    ).then(data => {
      const stores = makeLowercaseKeys(data.data).filter(
        el => !el.hidden && el.name !== 'Urban Buds - Distro'
      );

      stores.forEach(store => {
        if (store.delivery_only === true) {
          store.name = store.name;
          store.address = null;
          store.zip = null;
          store.state = null;
          store.url = store.url;
        }
        if (store.id === 'C-001397.14136') {
          store.url = 'https://trydoobie.com/cresco';
        }
        if (store.id === 'C-001197.12488') {
          store.name = 'Bud Bus';
          store.url = 'https://budbus.com/menu/';
        }
      });

      const searchLocationCoords = {
        lat: stores[0].near_lat,
        lng: stores[0].near_lng
      };
      this.setState({
        stores: stores,
        infoMessage: `Stores closest to ${this.state.searchLocation}`,
        totalPages: Math.floor(data.total_rows / this.state.limit),
        loading: false,
        bounds: this.getMapBounds(stores, searchLocationCoords),
        searchLocationCoords: searchLocationCoords
      });
    });
  }

  componentDidMount() {
    //set up google
    Geocode.setApiKey(apiKey);
    Geocode.setLanguage('en');
    Geocode.setRegion('es');
    // Geocode.enableDebug();

    //wait for wholesale customer data from api then set state.stores to it
    this.getStores();
  }

  componentDidUpdate(prevProps, prevState) {
    //the pagination is driven by offset, so we check to see if it changed, and re-run our api call
    if (
      this.state.offset !== prevState.offset ||
      this.state.searchLocation !== prevState.searchLocation
    ) {
      this.getStores();
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  nextPage() {
    if (this.state.page + 1 <= this.state.totalPages) {
      const offset = this.state.page * this.state.limit;
      this.setState({
        infoWindowVisible: false,
        offset: offset,
        page: this.state.page + 1,
        loading: true
      });
    }
  }

  prevPage() {
    if (this.state.page - 1 !== 0) {
      const offset = (this.state.page - 2) * this.state.limit;
      this.setState({
        infoWindowVisible: false,
        offset: offset,
        page: this.state.page - 1,
        loading: true
      });
    }
  }

  getCurrentLocation = () => {
    const options = {
      enableHighAccuracy: false,
      timeout: 10 * 1000,
      maximumAge: 5 * 60 * 1000
    };
    const success = pos => {
      const crd = pos.coords;
      let zip = false;
      Geocode.fromLatLng(crd.latitude, crd.longitude).then(
        response => {
          for (let i = 0; i < response.results[0].address_components.length; i++) {
            for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
              switch (response.results[0].address_components[i].types[j]) {
                case 'postal_code':
                  zip = response.results[0].address_components[i].long_name;
                  break;
                default:
                  break;
              }
            }
          }
          this.setState({
            searchLocation: zip,
            zip: zip,
            offset: 0,
            page: 1,
            loading: true
          });
        },
        error => {
          this.setState({
            infoMessage: 'Could not get your location. Please search by Zip Code',
            loading: false
          });
        }
      );
    };
    const error = err => {
      this.setState({
        infoMessage: 'Could not get your location. Please search by Zip Code',
        loading: false
      });
    };
    navigator.geolocation.getCurrentPosition(success, error, options);
  };

  getMapBounds = (stores, locationMarkerCoords) => {
    const { google } = this.props;
    let bounds = new google.maps.LatLngBounds();
    for (let store of stores) {
      if (store.lat && store.lng) {
        bounds.extend({ lat: store.lat, lng: store.lng });
      }
    }
    bounds.extend({
      lat: locationMarkerCoords.lat,
      lng: locationMarkerCoords.lng
    });
    return bounds;
  };

  handleMarkerClick = (props, marker) => {
    this.setState({
      activeMarker: marker,
      activeID: props.id,
      zoom: 18,
      infoWindowVisible: true
    });
  };

  handleListItemClick = event => {
    const markerToShow = markerRefs.find(el => el.props.id === event.currentTarget.id.toString());

    this.setState({
      activeMarker: markerToShow.marker,
      activeID: markerToShow.props.id,
      infoWindowVisible: true
    });
  };

  setMarkerRef = ref => {
    markerRefs.push(ref);
  };

  setMapRef = ref => {
    if (!this.state.mapRef) {
      this.setState({ mapRef: ref });
    } else {
      this.state.mapRef.map.fitBounds(this.state.bounds);
      this.state.mapRef.map.panToBounds(this.state.bounds);
    }
  };

  renderCurrentLocationMarker = () => {
    return (
      <Marker
        position={{
          lat: this.state.searchLocationCoords.lat.toString(),
          lng: this.state.searchLocationCoords.lng.toString()
        }}
        zIndex={1000000000}
      />
    );
  };

  renderLocations = () => {
    return this.state.stores.map((store, index) => (
      <div
        key={index}
        id={store.id}
        onClick={this.handleListItemClick}
        className={`single-location ${
          this.state.activeID && this.state.activeID === store.id ? 'active' : 'not-active'
        }`}>
        <div className="store-information">
          <h6 className="store-name">{store.name}</h6>
          {store.usage_type && store.usage_type === 'Rec' && (
            <p className="usage-type">Adult Use</p>
          )}
          {store.usage_type && store.usage_type === 'Med' && <p className="usage-type">Medical</p>}
          {store.usage_type && store.usage_type === 'Both' && (
            <p className="usage-type">Medical and Adult Use</p>
          )}

          {!store.delivery_only ? (
            <p className="store-address">
              {store.address}, {store.city}, {store.state}
            </p>
          ) : (
            <p className="store-address">Delivery Only</p>
          )}

          {!store.delivery_only &&
            (store.miles ? (
              <p className="distance">{Math.round(store.miles)} miles away</p>
            ) : (
              <p className="distance">Less than a mile away</p>
            ))}
        </div>

        <div className="store-actions">
          {store.url && (
            <Link
              className="icon-button "
              url={store.url}
              rel="noopener noreferrer"
              target="_blank"
              title={store.url}>
              <img alt="" role="presentation" src={websiteIcon} />
              Website
            </Link>
          )}

          {!store.delivery_only && (
            <Link
              className="icon-button"
              url={`https://www.google.com/maps/dir/?api=1&destination=${store.address},${store.city},${store.state},${store.zip}`}
              rel="noopener noreferrer"
              target="_blank"
              title="Directions">
              <img alt="" role="presentation" src={locationIcon} />
              Directions
            </Link>
          )}
        </div>
      </div>
    ));
  };

  renderMarkers = () => {
    return this.state.stores.map((store, i) => {
      const marker = (
        <Marker
          key={i}
          id={store.id}
          position={{
            lat: store.lat && store.lat.toString(),
            lng: store.lng && store.lng.toString()
          }}
          icon={mapMarker}
          onClick={this.handleMarkerClick}
          ref={this.setMarkerRef}
        />
      );
      return marker;
    });
  };

  renderInfoWindowData = id => {
    const store = this.state.stores.find(el => el.id === id);

    if (!store) {
      return <></>;
    }
    const directionsLink =
      store &&
      !store.delivery_only &&
      `https://www.google.com/maps/dir/Current+Location/${store.lat},${store.lng}`;

    return (
      store && (
        <ProviderWithRouter>
          <ThemeProvider theme={theme}>
            <div className="info-window-content">
              <h2 className="store-name">{store.name}</h2>
              {store.usage_type && store.usage_type === 'Rec' && (
                <p className="usage-type">Adult Use</p>
              )}
              {store.usage_type && store.usage_type === 'Med' && (
                <p className="usage-type">Medical</p>
              )}
              {store.usage_type && store.usage_type === 'Both' && (
                <p className="usage-type">Medical and Adult Use</p>
              )}

              {!store.delivery_only ? (
                <p className="store-address">
                  {store.address}, {store.city}, {store.state}
                </p>
              ) : (
                <p className="store-address">Delivery Only</p>
              )}

              <StlInfoButtonContainer className="button-container no-margin">
                {directionsLink && (
                  <SimpleLink
                    url={directionsLink}
                    variant="contained"
                    target="_blank"
                    text="Directions"
                    style={{ width: '100%', height: '40px', outline: 'none' }}
                  />
                )}

                {store.url && (
                  <SimpleLink
                    url={store?.url}
                    variant="contained"
                    target="_blank"
                    text="Visit Website"
                    style={{ width: '100%', height: '40px', outline: 'none' }}
                  />
                )}
              </StlInfoButtonContainer>
            </div>
          </ThemeProvider>
        </ProviderWithRouter>
      )
    );
  };

  handleSearchForm = e => {
    e.preventDefault();
    if (this.state.zip === null || this.state.zip === '') {
      this.setState({
        infoMessage: 'Please enter a zip code'
      });
      return false;
    }
    this.setState({
      searchLocation: this.state.zip,
      offset: 0,
      page: 1,
      loading: true
    });
  };

  handleLocation = () => {
    this.getCurrentLocation();
    this.setState({
      loading: true,
      infoMessage: 'Getting your current location...'
    });
  };

  render() {
    const { google } = this.props;
    const {
      activeMarker,
      activeID,
      infoWindowVisible,
      stores,
      infoMessage,
      page,
      totalPages,
      loading,
      searchLocationCoords
    } = this.state;

    if (!stores) {
      if (loading) {
        return (
          <div className="loading-screen">
            <Loader $minHeight="800px" />
          </div>
        );
      }
    }

    return (
      stores && (
        <div className="map-wrapper padding dark-flower ">
          <div className="locations-list-wrapper background-wrapper">
            <div className="locations-search-form-wrapper text-center">
              <h2 id="title" className="locations-list-title">
                Find Your Cresco
              </h2>
              <StlButtonContainer
                className="locations-search-form button-container center no-margin"
                onSubmit={this.handleSearchForm}>
                <TextField
                  id="zip-code"
                  label="Zip Code"
                  variant="outlined"
                  color="primary"
                  required={true}
                  onChange={event => this.setState({ zip: event.target.value })}
                />
                <button onClick={this.handleSearchForm} className="contained-button">
                  Search
                </button>
              </StlButtonContainer>
            </div>
            <div className="section-container">
              <section className="map-list-wrapper">
                <div className="relative map-wrapper">
                  <Map
                    ref={ref => this.setMapRef(ref)}
                    google={google}
                    position="relative"
                    className="interactive-map"
                    zoomControl={true}
                    mapTypeControl={false}
                    scaleControl={true}
                    rotateControl={true}
                    scrollwheel={false}
                    fullscreenControl={false}
                    streetViewControl={true}
                    gestureHandling="greedy"
                    zoom={18}
                    styles={mapStyle}>
                    {this.renderMarkers()}

                    {searchLocationCoords && this.renderCurrentLocationMarker()}

                    <InfoWindow
                      marker={activeMarker}
                      visible={infoWindowVisible}
                      children={this.renderInfoWindowData(activeID)}
                    />
                  </Map>
                </div>
                <div>
                  {infoMessage && <div className="info-message">{infoMessage}</div>}

                  {page && totalPages && (
                    <div className="pagination-nav">
                      <div className="page-number">
                        Page {page} of {totalPages}
                      </div>
                      <div className="page-controls">
                        <span onClick={() => this.prevPage()}>Prev</span>
                        <span onClick={() => this.nextPage()}>Next</span>
                      </div>
                    </div>
                  )}

                  <div className="locations-list">
                    {loading ? (
                      <Loader $minHeight="150px" className="small" />
                    ) : (
                      this.renderLocations(stores)
                    )}
                  </div>
                </div>
              </section>
            </div>
            {!loading && (
              <div className="button-container center text-center back-to-top">
                <StlBackToTopLink href="#title" className="contained-button flex-center">
                  BACK TO TOP
                </StlBackToTopLink>
              </div>
            )}
          </div>
        </div>
      )
    );
  }
}

export default GoogleApiWrapper({
  apiKey: apiKey
})(StoreLocatorPage);
