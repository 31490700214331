import React, { useEffect, useState } from 'react';
import { PrismicRichText } from '@prismicio/react';
import { useSelector, useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { get } from 'lodash';
import renderButtons from './../helpers/renderButtons';

export default function ProductFeatureSlice({slice}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('420'));
  const [products, setProducts] = useState(false);

  const loading = useSelector(state => get(state, 'prismic.products_list.loading', true));
  const data = useSelector(state => get(state, 'prismic.products_list.data', false));

  useEffect(() => {
    dispatch({
      type: 'PRISMIC_LOAD_REPEATABLE',
      key: 'products',
      reduxKey: 'products_list'
    });
  }, [dispatch]);

  useEffect(() => {
    if (data && !loading) {
      const sortedData = data.sort((a, b) => {
        return a.data.order - b.data.order;
      });
      setProducts(sortedData);
    }
  }, [data, loading]);

  const backgroundStyle = {
    backgroundColor: slice.primary.background_color,
    color: slice.primary.text_color
  };

  const generateCustomButtons = product => {
    const buttons = [
      {
        active: true,
        cta_link: {
          uid: `products/${product.uid}`
        },
        cta_text: `More About ${
          get(product, 'data.product_name[0].text') !== 'Liquid Live Resin'
            ? get(product, 'data.product_name[0].text')
            : 'LLR'
        }`,
        button_variant: 'outlined',
        button_color: 'primary'
      },
      {
        active: true,
        cta_link: {
          uid: 'products'
        },
        cta_text: 'View All Products',
        button_variant: 'contained',
        button_color: 'primary'
      }
    ];
    return buttons;
  };

  return (
    products && (
      <div className="product-feature-section cool-margin section-padding" style={backgroundStyle}>
        <div className="wrapper">
          {!isMobile && (
            <div className="text-content center-align mb-20">
              <PrismicRichText field={slice.primary.content} />
            </div>
          )}

          <div className="products">
            {data.map((product, i) => (
              <div key={i} className="product">
                <div className="text-content">
                  <img
                    src={product.data.product_image.url}
                    className="product-hero-image"
                    alt={'Cresco Cannabis' + get(product, 'data.product_name[0].text')}
                  />
                  <h3>{get(product, 'data.product_name[0].text')}</h3>
                  <PrismicRichText field={product.data.product_excerpt} />
                  <div className="button-container">
                    {renderButtons(generateCustomButtons(product))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  );
}
