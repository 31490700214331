import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const CustomCarousel = ({ items, thumbs }) => {
  return (
    <Carousel thumbs={thumbs} showStatus={false} showIndicators={false}>
      {items.map((el, i) => (
        <div key={i}>
          <img
            src={el.photo !== undefined ? el.photo.url : el.image.url}
            alt="Product"
          />
        </div>
      ))}
    </Carousel>
  );
};

export default CustomCarousel;
