import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { get } from 'lodash';

export default function TextSlider({slice}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('768'));
  const showIndicators = get(slice, 'items').length > 1 ? true : false;
  const mobileImg = get(slice, 'primary.mobile_image.url');
  const desktopImg = get(slice, 'primary.image.url');
  const background = get(slice, 'primary.background_image.url');

  const backgroundStyle = {
    backgroundColor: get(slice, 'primary.background_color'),
    color: get(slice, 'primary.text_color')
  };

  const backgroundImage = {
    background: 'url(' + background + ')'
  };

  const renderSlides = slides => {
    return slides.map((el, i) => (
      <div className="slide" key={i}>
        <div className="text-content section-padding-2">
          <PrismicRichText field={get(el, 'content')} />
        </div>
      </div>
    ));
  };

  return (
    <section className="text-slider-slice cool-margin" style={backgroundStyle}>
      {background && <div className="background-image" style={backgroundImage}></div>}
      <div className="wrapper">
        {!isMobile && desktopImg && (
          <div className="image-container">
            <img src={desktopImg} alt="liquid live resin cartidge full of the live resin" />
          </div>
        )}
        {isMobile && mobileImg && (
          <div className="mobile-image-container">
            <img src={mobileImg} alt="liquid live resin cartidge full of the live resin" />
          </div>
        )}

        <div className="slider-container">
          <Carousel
            className="questions-carousel"
            showArrows={true}
            showThumbs={false}
            showIndicators={showIndicators}
            showStatus={false}>
            {renderSlides(get(slice, 'items'))}
          </Carousel>
        </div>
      </div>
    </section>
  );
}
