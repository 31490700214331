import React, { useState, useEffect } from 'react';
import { PrismicRichText } from '@prismicio/react';
import { Carousel } from 'react-responsive-carousel';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';

const slides = [{ all: true, data: { color: '#214A92' } }];

export default function ProductFeatureSlice({slice}) {
  const dispatch = useDispatch();
  const [currentSlide, setCurrentSlide] = useState(0);
  const handleNavClick = index => {
    setCurrentSlide(index);
  };
  const loading = useSelector(state => get(state, 'prismic.benefits_list.loading', true));
  const data = useSelector(state => get(state, 'prismic.benefits_list.data', false));

  useEffect(() => {
    dispatch({
      type: 'PRISMIC_LOAD_REPEATABLE',
      key: 'benefits',
      reduxKey: 'benefits_list'
    });
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      const sortedData = data.sort((a, b) => {
        return a.data.order - b.data.order;
      });
      sortedData.map(el => {
        slides.push(el);
        return false;
      });
    }
  }, [loading, data, slides]);

  const backgroundStyle = {
    backgroundColor: slice.primary.background_color,
    color: slice.primary.text_color
  };

  const textColor = {
    color: slides[currentSlide].data.color,
    opacity: '1'
  };
  const borderColor = {
    borderColor: slides[currentSlide].data.color
  };

  const renderNavigation = benefits => {
    return benefits.map((benefit, i) => (
      <span
        key={i}
        onClick={() => handleNavClick(i + 1)}
        style={currentSlide === i + 1 ? textColor : { color: '#214A92', opacity: '.75' }}>
        {benefit.data.benefit_name[0].text}
      </span>
    ));
  };

  const renderBenefitIcons = benefits => {
    return benefits.map((benefit, i) => (
      <span key={i} onClick={() => handleNavClick(i + 1)}>
        <img src={benefit.data.icon.url} alt="Products"></img>
      </span>
    ));
  };

  const renderSlides = benefits => {
    return benefits.map((el, i) => (
      <div key={i} className="benefit-slide">
        <div className="slide-wrapper">
          <div className="benefit-icon" style={borderColor}>
            <img src={el.data.icon.url} alt="Products" />
          </div>
          <div className="text-content">
            <div className="rich-content" style={textColor}>
              <PrismicRichText field={el.data.content} />
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    !loading &&
    data && (
      <div className="benefits-feature-slice cool-margin section-padding" style={backgroundStyle}>
        <div className="wrapper">
          <div className="text-content center-align mb-40">
            <PrismicRichText field={slice.primary.content} />
          </div>

          <div className="benefits-slider">
            <div className="nav">
              <span
                onClick={() => handleNavClick(0)}
                style={currentSlide === 0 ? textColor : { color: '#214A92', opacity: '.75' }}>
                All
              </span>
              {renderNavigation(data)}
            </div>

            <Carousel
              showArrows={false}
              showThumbs={false}
              showIndicators={true}
              showStatus={false}
              className="slider-content"
              selectedItem={currentSlide}
              swipeable={false}>
              <div className="benefit-slide All">
                <div className="benefit-icons">{renderBenefitIcons(data)}</div>
              </div>
              {renderSlides(data)}
            </Carousel>
          </div>
        </div>
      </div>
    )
  );
}
