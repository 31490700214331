import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import classNames from 'classnames';

const LazyLoadingImage = ({ className, wrapperClassName, objectFit, ...rest }) => {
  return (
    <div className={classNames(className, 'relative')}>
      <LazyLoadImage
        effect="opacity"
        wrapperClassName={classNames(wrapperClassName, 'display-block')}
        {...rest}
      />
    </div>
  );
};

export default LazyLoadingImage;
