import farneseneIcon from '../imagesV2/icons/terpenes/Terpene_Farnesene_Secondary.png';
import valenceneIcon from '../imagesV2/icons/terpenes/Terpene_Valencene_Secondary.png';
import caryophylleneIcon from '../imagesV2/icons/terpenes/Terpene_Caryophyllene_Secondary.png';
import humuleneIcon from '../imagesV2/icons/terpenes/Terpene_Humulene_Secondary.png';
import limoneneIcon from '../imagesV2/icons/terpenes/Terpene_Limonene_Secondary.png';
import linaloolIcon from '../imagesV2/icons/terpenes/TerpeneLinalool_Secondary.png';
import myrceneIcon from '../imagesV2/icons/terpenes/Terpene_Myrcene_Secondary.png';
import ocimeneIcon from '../imagesV2/icons/terpenes/Terpene_Ocimene_Secondary.png';
import terpinolineIcon from '../imagesV2/icons/terpenes/Terpene_Terpinolene_Secondary.png';
import pineneIcon from '../imagesV2/icons/terpenes/Terpene_Pinene_Secondary.png';
import nerolidolIcon from '../imagesV2/icons/terpenes/Terpene_Nerolidol_Secondary.png';
import cymeneIcon from '../imagesV2/icons/terpenes/Terpene_Cymene_Secondary.png';

export const renderTerpenes = terpenes => {
  return (
    <div className="terpenes">
      {terpenes.caryophyllene && (
        <div>
          <figure className="contain-img height-auto">
            <img src={caryophylleneIcon} alt="caryophyllene" />
          </figure>
          <span className="name">caryophyllene</span>
        </div>
      )}
      {terpenes.cymene && (
        <div>
          <figure className="contain-img height-auto">
            <img src={cymeneIcon} alt="caryophyllene" />
          </figure>
          <span className="name">cymene</span>
        </div>
      )}
      {terpenes.humulene && (
        <div>
          <figure className="contain-img height-auto">
            <img src={humuleneIcon} alt="humulene" />
          </figure>
          <span className="name">humulene</span>
        </div>
      )}
      {terpenes.limonene && (
        <div>
          <figure className="contain-img height-auto">
            <img src={limoneneIcon} alt="limonene" />
          </figure>
          <span className="name">limonene</span>
        </div>
      )}
      {terpenes.linalool && (
        <div>
          <figure className="contain-img height-auto">
            <img src={linaloolIcon} alt="linalool" />
          </figure>
          <span className="name">linalool</span>
        </div>
      )}
      {terpenes.myrcene && (
        <div>
          <figure className="contain-img height-auto">
            <img src={myrceneIcon} alt="myrcene" />
          </figure>
          <span className="name">myrcene</span>
        </div>
      )}
      {terpenes.ocimene && (
        <div>
          <figure className="contain-img height-auto">
            <img src={ocimeneIcon} alt="ocimene" />
          </figure>
          <span className="name">ocimene</span>
        </div>
      )}
      {terpenes.pinene && (
        <div>
          <figure className="contain-img height-auto">
            <img src={pineneIcon} alt="pinene" />
          </figure>
          <span className="name">pinene</span>
        </div>
      )}
      {terpenes.nerolidol && (
        <div>
          <figure className="contain-img height-auto">
            <img src={nerolidolIcon} alt="nerolidol" />
          </figure>
          <span className="name">nerolidol</span>
        </div>
      )}
      {terpenes.terpinoline && (
        <div>
          <figure className="contain-img height-auto">
            <img src={terpinolineIcon} alt="terpinoline" />
          </figure>
          <span className="name">terpinoline</span>
        </div>
      )}
      {terpenes.farnesene && (
        <div>
          <figure className="contain-img height-auto">
            <img src={farneseneIcon} alt="farnesene" />
          </figure>
          <span className="name">farnesene</span>
        </div>
      )}
      {terpenes.valencene && (
        <div>
          <figure className="contain-img height-auto">
            <img src={valenceneIcon} alt="valencene" />
          </figure>
          <span className="name">valencene</span>
        </div>
      )}
    </div>
  );
};
