import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const StateDropdown = ({ value, onChange, style, options, className }) => {
  return (
    <FormControl>
      <TextField
        color="primary"
        variant="outlined"
        id="select-strain-state"
        className={className}
        select
        name="select-strain"
        label="Filter By State"
        value={value}
        onChange={onChange}
        style={style}>
        <MenuItem value="All">All</MenuItem>
        {options.map((option, i) => (
          <MenuItem key={i} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};

export default StateDropdown;
