import ThirdPartyLinkingDialog from './ThirdPartyLinkingDialog';
import CookieComplianceDialog from './CookieComplianceDialog';

export const handleDisableBackdropClick = (event, reason) => {
  if (reason === 'backdropClick') return false;
};

export default function Dialogs() {
  return (
    <>
      <ThirdPartyLinkingDialog />
      <CookieComplianceDialog />
    </>
  );
}
