import React from 'react';
import { Button } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import Link from './../components/Link';
import classNames from 'classnames';

// Can be used for buttons within repeatable content fields
export const SimpleLink = ({ key, to, url, target, variant, text, onClick, className }) => {
  return (
    <Link
      className={classNames('inline-block ', className)}
      key={key}
      to={to ? '/' + to : undefined}
      onClick={onClick}
      url={url}
      target={target}>
      <Button variant={variant} disableElevation className="scale-text simple-link">
        <p>{text}</p>
      </Button>
    </Link>
  );
};

export default function renderButtons(buttons) {
  if (!buttons) return null;
  return buttons.map((button, i) => {
    const manualLink = get(button, 'manual_link[0].text');
    const showButton = manualLink ? manualLink : get(button, 'cta_link.uid');

    if (button.onclick) {
      return (
        <Link url="#">
          <Button
            className="myButton scale-text"
            variant={button?.button_variant}
            disableElevation
            disableRipple
            onClick={button?.onclick ? button?.onclick : null}>
            <p>{get(button, 'cta_text[0].text') || button?.cta_text}</p>
          </Button>
        </Link>
      );
    } else if (
      !isEmpty(button.cta_text) &&
      (get(button, 'cta_text[0].text') || button?.cta_text) &&
      (get(button, 'cta_link.url') || manualLink || get(button, 'cta_link.uid'))
    ) {
      return (
        <Link
          key={i}
          to={'/' + showButton}
          url={get(button, 'cta_link.url')}
          target={get(button, 'cta_link.target')}>
          <Button
            variant={button?.button_variant}
            disableElevation
            disableRipple
            className="scale-text">
            <p>{get(button, 'cta_text[0].text') || button?.cta_text}</p>
          </Button>
        </Link>
      );
    }
  });
}
