import React from 'react';
import PrismicRichText from '../components/PrismicRichText';
import { get, map } from 'lodash';
import { styled } from 'styled-components';
import LazyLoadingImage from '../components/LazyLoadImage';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { SimpleLink } from '../helpers/renderButtonsV2';

const StlLazyLoadingImage = styled(LazyLoadingImage)`
  // width: 124px;
  @media screen and (min-width: 600px) {
    // width: 265px;
  }
`;

const StlMainWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  @media screen and (min-width: 900px) {
    gap: 60px;
  }
`;

const StlImageCopyWrapper = styled.div`
  max-width: 509px;
  min-height: 195px;
  display: grid;
  grid-template-columns: 150px auto;
  h4 {
    text-transform: capitalize;
  }
  p {
    font-size: 11px;
    margin-top: 10px;
  }

  .copy {
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  @media screen and (min-width: 600px) {
    h4 {
      font-size: 1.8rem;
    }
    p {
      font-size: 1.4rem;
    }
    .copy {
      padding: 20px 30px;
    }
  }
`;

const NewLogoSectionWithContent = ({ slice }) => {
  const background = get(slice, 'primary.background_style');
  const showBackground = background === 'Dark Flower' ? 'dark-flower' : 'dark-blue-splatter';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('900'));

  return (
    <div className={showBackground}>
      <div className="background-wrapper padding">
        <div className="wrapper">
          <div className="text-center richtext-section">
            <PrismicRichText render={get(slice, 'primary.content')} />
          </div>
          <StlMainWrapper>
            {map(slice.items, (el, i) => {
              return (
                (get(el, 'cta_link.url') || get(el, 'cta_link.uid')) && (
                  <div>
                    <StlImageCopyWrapper key={i} className="relative border">
                      <StlLazyLoadingImage
                        className="img cover-img scale"
                        src={isMobile ? get(el, 'image.Mobile.url') : get(el, 'image.url')}
                        alt={isMobile ? get(el, 'image.Mobile.alt') : get(el, 'image.alt')}
                        wrapperClassName="display-block"
                      />
                      <div className="copy">
                        <PrismicRichText render={el?.copy} />
                      </div>
                    </StlImageCopyWrapper>
                    <div className="button-container center smaller-margin">
                      <SimpleLink
                        key={i}
                        to={get(el, 'cta_link.uid')}
                        url={get(el, 'cta_link.url')}
                        target={get(el, 'cta_link.target')}
                        variant={el?.button_variant}
                        text={get(el, 'cta_text[0].text')}
                        className="simple-link"
                      />
                    </div>
                  </div>
                )
              );
            })}
          </StlMainWrapper>
        </div>
      </div>
    </div>
  );
};

export default NewLogoSectionWithContent;
