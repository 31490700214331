// import * as prismic from '@prismicio/client';

// const endpoint = prismic.getRepositoryEndpoint('cresco-cannabis-staging');
// const accessToken =
//   'MC5ZQ1VfUkJJQUFDSUFUSWh4.77-977-9cO-_vT3vv73vv73vv70jHe-_vS0O77-977-9Fe-_vXHvv71cVgjvv71H77-977-977-977-9Enjvv73vv70'; // Set an access token
// const routes = [];

// const client = prismic.createClient(endpoint, { routes, accessToken });

// export default client;

import * as prismic from '@prismicio/client';

export const repositoryName = 'cresco-cannabis-staging';

const client = prismic.createClient(repositoryName, {
  accessToken:
    'MC5ZQ1VfUkJJQUFDSUFUSWh4.77-977-9cO-_vT3vv73vv73vv70jHe-_vS0O77-977-9Fe-_vXHvv71cVgjvv71H77-977-977-977-9Enjvv73vv70',
  routes: []
});

export default client;
