import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import Cookie from 'js-cookie';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import { lighterBlue } from '../../constants/colors';
import classNames from 'classnames';

const StlSwitch = styled(Switch)`
  .MuiSwitch-track {
    background: ${lighterBlue};
  }
`;

const StlFormControlLabel = styled(FormControlLabel)`
  .MuiTypography-root {
    color: ${lighterBlue};
    font-size: 1.4rem;
    @media screen and (min-width: 900px) {
      font-size: 1.8rem;
    }
  }
`;

export default function AgeGate(props) {
  const dispatch = useDispatch();

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [error, setError] = useState(false);
  const [verified, setVerified] = useState(false);
  const [spanish, toggleSpanish] = useState(false);

  const pageLoading = useSelector(state => get(state, 'prismic.age_gate.loading', true));
  const page = useSelector(state => get(state, 'prismic.age_gate.data', false));
  const errorMessage = get(page, 'age_gate[0].error_message[0].text');
  const textColor = get(page, 'age_gate[0].text_color');
  const overlayColor = get(page, 'age_gate[0].overlay[0].text');

  const copy = {
    english: {
      headline: 'Are You Over 21?',
      body: (
        <>
          Yes, I am at least twenty-one (21) years of age or am otherwise a qualified patient. By
          clicking “I agree” and entering the website, I agree to be bound by the{' '}
          <Link to="/terms-of-service">Terms of Service</Link> and{' '}
          <Link to="/privacy-policy">Privacy Policy</Link>.
        </>
      ),
      checkbox: 'I Agree',
      button: 'Enter Site'
    },
    spanish: {
      headline: '¿Es mayor de 21 años?',
      body: (
        <>
          Sí, tengo al menos veintiún (21) años o soy un paciente calificado. Al hacer clic en
          “Acepto” y entrar en el sitio web, acepto los{' '}
          <Link to="/terms-of-service">Términos de servicio</Link> y la{' '}
          <Link to="/privacy-policy">Política de privacidad</Link>.
        </>
      ),
      checkbox: 'Acepto',
      button: 'Ingresar al sitio'
    }
  };

  const localizedCopy = spanish ? copy.spanish : copy.english;

  useEffect(() => {
    dispatch({
      type: 'PRISMIC_LOAD_SINGLE_PAGE',
      key: 'age_gate'
    });
  }, [dispatch]);

  const handleCheckbox = () => {
    setCheckboxChecked(!checkboxChecked);
  };
  const handleSwitch = () => {
    toggleSpanish(prev => !prev);
  };

  const allowPassage = event => {
    event.preventDefault();
    if (checkboxChecked) {
      Cookie.set('cresco_over21', true, { expires: 3 });
      setVerified(true);
      props.verifyAge();
    } else {
      setError(true);
    }
  };

  const textColorStyle = {
    color: textColor
  };

  return (
    !pageLoading &&
    !verified && (
      <div id="age-gate">
        {overlayColor && <div className="overlay" style={{ background: 'rgba(0,0,0,.8)' }}></div>}

        <div className="age-gate-content dark-blue-splatter">
          <div className="spanish-toggle">
            <StlFormControlLabel
              control={<StlSwitch checked={spanish} onChange={handleSwitch} />}
            />
            <span class="switch-text">{!spanish ? 'ES' : 'EN'}</span>
          </div>
          <div className="background-wrapper">
            <div className="content-section" style={textColorStyle}>
              <h2 className={classNames(!spanish && 'nowrap')}>{localizedCopy.headline}</h2>
              <p>{localizedCopy.body}</p>
              <form id="age-gate-form" onSubmit={allowPassage}>
                <FormControlLabel
                  value="start"
                  className="mb-20"
                  control={
                    <Checkbox
                      checked={checkboxChecked}
                      onChange={handleCheckbox}
                      color="primary"
                      inputProps={{ 'aria-label': localizedCopy.checkbox }}
                    />
                  }
                  label={localizedCopy.checkbox}
                  labelPlacement="end"
                />

                {error && !checkboxChecked && (
                  <div className="error-container">
                    <p className="error-message">
                      {errorMessage ? errorMessage : 'Please confirm you are over 21 years of age.'}
                    </p>
                  </div>
                )}
                <br></br>
                <div className="button-container center no-margin">
                  <button type="submit" className="contained-button scale-text">
                    {localizedCopy.button}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
