import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import { darkBlue } from '../../constants/colors';
import LazyLoadingImage from '../LazyLoadImage';

const StlImageWrapper = styled.div`
  width: 157px;
  min-height: 157px;
  position: relative;
  overflow: hidden;

  @media screen and (min-width: 900px) {
    width: 220px;
    height: 220px;
  }
`;

const StlStrainWrapper = styled.div`
  color: ${darkBlue};
  padding: 11px 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  h6 {
    font-size: 1.2rem;
  }

  @media screen and (min-width: 900px) {
    padding: 20px 26px;
    h6 {
      font-size: 1.6rem;
    }
  }
`;

const StrainCard = ({ strainID, strainName, strainImage, cookie, className }) => {
  return (
    <div className={className}>
      <Link
        className="scale flex-column height-100 no-underline"
        onClick={
          cookie
            ? () => {
                cookie.set('just_viewed', strainID);
              }
            : undefined
        }
        to={`/strains/${strainID}`}>
        <StlImageWrapper className="img-wrapper">
          <LazyLoadingImage
            className="cover-img"
            src={strainImage}
            alt={'Cresco Cannabis' + strainName}
            wrapperClassName="display-block"
          />
        </StlImageWrapper>
        <StlStrainWrapper className="blue-gradient">
          <h6 className="text-center dark-blue">{strainName}</h6>
        </StlStrainWrapper>
      </Link>
    </div>
  );
};

export default StrainCard;
