import React, { useEffect } from 'react';
import PrismicRichText from '../components/PrismicRichText';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { get } from 'lodash';
import { ReactComponent as LineOne } from '../imagesV2/custom-lines/line-1.svg';
import { ReactComponent as LineTwo } from '../imagesV2/custom-lines/line-2.svg';
import { ReactComponent as LineThree } from '../imagesV2/custom-lines/line-3.svg';

export default function SpecialImageContent({ slice }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('900'));
  const backgroundImage = isMobile
    ? get(slice.primary.image.Mobile, 'url')
    : get(slice.primary.image, 'url');

  const backgroundStyle = {
    color: slice.primary.text_color,
    backgroundColor: slice.primary.background_color
  };

  const logos = get(slice.primary.logos, 'url');
  const logosLink = get(slice.primary.logos_link, 'url');

  const className = get(slice.primary.classname, '[0].text');

  const alignRight = get(slice.primary, 'align_content_right') ? 'align-right' : 'align-left';

  useEffect(() => {
    const elements = document.getElementsByClassName('custom-animation');

    const hasAnimatedElements = elements.length > 0;

    const animateElements = elements => {
      for (let el of elements) {
        if (el.offsetTop - window.outerHeight * 0.75 < window.pageYOffset) {
          el.classList.add('animation-active');
        }
        if (el.offsetTop + el.clientHeight - window.outerHeight < window.pageYOffset) {
          el.classList.add('scrolled-to-bottom');
        }
      }
    };

    if (hasAnimatedElements) {
      animateElements(elements);
      window.onscroll = () => {
        animateElements(elements);
      };
    }
  });

  return (
    <section
      className={`special-image-content ${className} custom-animation`}
      style={backgroundStyle}>
      <div className="wrapper">
        <div className={`text-content section-padding ${alignRight}`}>
          <PrismicRichText render={slice.primary.content} />
          {logos && (
            <a href={logosLink} target="_blank" rel="noopener noreferrer">
              <img src={logos} className="logos" alt="Brand Logos" />
            </a>
          )}
        </div>

        {backgroundImage && (
          <div className="image-content">
            <img src={backgroundImage} alt="Background" />
          </div>
        )}

        {className === 'section-5' && (
          <div className="line-custom-animation">
            <div className="wrap">
              <LineOne />
            </div>
          </div>
        )}
        {className === 'section-6' && (
          <div className="line-custom-animation">
            <div className="wrap right">
              <LineTwo />
            </div>
          </div>
        )}
        {className === 'section-7' && (
          <div className="line-custom-animation">
            <div className="wrap">
              <LineThree />
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
