import React from 'react';
import PrismicRichText from '../components/PrismicRichText';
import renderButtons from './../helpers/renderButtonsV2';
import { get, isEmpty } from 'lodash';

export default function CTASlice({ slice }) {
  const backgroundStyle = {
    color: slice.primary.text_color,
    backgroundColor: slice.primary.background_color
  };

  return (
    <section className="cta-slice section-padding" style={backgroundStyle}>
      <div className="wrapper">
        <div className="text-content">
          <PrismicRichText render={get(slice, 'primary.content')} />
        </div>

        {!isEmpty(slice.items) && <div>{renderButtons(slice.items)}</div>}
        {!isEmpty(get(slice, 'primary.postscript_content')) && (
          <div className="text-content-bottom">
            <PrismicRichText render={get(slice, 'primary.postscript_content')} />
          </div>
        )}
      </div>
    </section>
  );
}
