import filledApple from '../imagesV2/vector-primary-icons/CrescoWebsite_Icon_Terpinolene_Primary.png';
import outlinedApple from '../imagesV2/vector-secondary-icons/CrescoWebsite_Icon_Terpinolene_Secondary.png';
import filledLemon from '../imagesV2/vector-primary-icons/CrescoWebsite_Icon_Limonene_Primary.png';
import outlinedLemon from '../imagesV2/vector-secondary-icons/CrescoWebsite_Icon_Limonene_Secondary.png';
import filledClove from '../imagesV2/vector-primary-icons/CrescoWebsite_Icon_Myrcene_Primary.png';
import outlinedClove from '../imagesV2/vector-secondary-icons/CrescoWebsite_Icon_Myrcene_Secondary.png';
import filledPepper from '../imagesV2/vector-primary-icons/CrescoWebsite_Icon_Spice_Primary.png';
import outlinedPepper from '../imagesV2/vector-secondary-icons/CrescoWebsite_Icon_Spice_Secondary.png';
import filledEarth from '../imagesV2/vector-primary-icons/CrescoWebsite_Icon_Earth_Primary.png';
import outlinedEarth from '../imagesV2/vector-secondary-icons/CrescoWebsite_Icon_Earth_Secondary.png';
import filledFlower from '../imagesV2/vector-primary-icons/CrescoWebsite_Icon_Nerolidol_Primary.png';
import outlinedFlower from '../imagesV2/vector-secondary-icons/CrescoWebsite_Icon_Nerolidol_Secondary.png';
import filledPine from '../imagesV2/vector-primary-icons/CrescoWebsite_Icon_APinene_Primary.png';
import outlinedPine from '../imagesV2/vector-secondary-icons/CrescoWebsite_Icon_APinene_Secondary.png';
import filledSweet from '../imagesV2/vector-primary-icons/CrescoWebsite_Icon_Sweet_Primary.png';
import outlinedSweet from '../imagesV2/vector-secondary-icons/CrescoWebsite_Icon_Sweet_Secondary.png';
import filledLavender from '../imagesV2/vector-primary-icons/CrescoWebsite_Icon_Linalool_Primary.png';
import outlinedLavender from '../imagesV2/vector-secondary-icons/CrescoWebsite_Icon_Linalool_Secondary.png';
import filledHerbal from '../imagesV2/vector-primary-icons/CrescoWebsite_Icon_Ocimene_Primary.png';
import outlinedHerbal from '../imagesV2/vector-secondary-icons/CrescoWebsite_Icon_Ocimene_Secondary.png';
import filledBark from '../imagesV2/vector-primary-icons/CrescoWebsite_Icon_Cymene_Primary.png';
import outlinedBark from '../imagesV2/vector-secondary-icons/CrescoWebsite_Icon_Cymene_Secondary.png';

export const renderTaste = (taste, selected) => {
  switch (taste) {
    case 'Sweet':
      return selected ? filledSweet : outlinedSweet;

    case 'Pine':
      return selected ? filledPine : outlinedPine;

    case 'Lemon':
      return selected ? filledLemon : outlinedLemon;

    case 'Floral':
      return selected ? filledFlower : outlinedFlower;

    case 'Lavender':
      return selected ? filledLavender : outlinedLavender;

    case 'Spice':
      return selected ? filledClove : outlinedClove;

    case 'Herbal':
      return selected ? filledHerbal : outlinedHerbal;

    case 'Bark':
      return selected ? filledBark : outlinedBark;
  }
};

// export const renderTaste = taste => {
//   switch (taste) {
//     case 'Sweet':
//       return (
//         <button>
//           <img className="filled" src={filledSweet} />
//           <img className="outlined" src={outlinedSweet} />
//         </button>
//       );

//     case 'Pine':
//       return (
//         <button>
//           <img className="filled" src={filledPine} />
//           <img className="outlined" src={outlinedPine} />
//         </button>
//       );

//     case 'Lemon':
//       return (
//         <button>
//           <img className="filled" src={filledLemon} />
//           <img className="outlined" src={outlinedLemon} />
//         </button>
//       );

//     case 'Floral':
//       return (
//         <button>
//           <img className="filled" src={filledFlower} />
//           <img className="outlined" src={outlinedFlower} />
//         </button>
//       );

//     case 'Lavender':
//       return (
//         <button>
//           <img className="filled" src={filledLavender} />
//           <img className="outlined" src={outlinedLavender} />
//         </button>
//       );

//     case 'Spice':
//       return (
//         <button>
//           <img className="filled" src={filledClove} />
//           <img className="outlined" src={outlinedClove} />
//         </button>
//       );

//     case 'Herbal':
//       return (
//         <button>
//           <img className="filled" src={filledHerbal} />
//           <img className="outlined" src={outlinedHerbal} />
//         </button>
//       );

//     case 'Bark':
//       return (
//         <button>
//           <img className="filled" src={filledBark} />
//           <img className="outlined" src={outlinedBark} />
//         </button>
//       );
//   }
// };

export const renderSmell = (smell, selected) => {
  switch (smell) {
    case 'Fruity':
      return selected ? filledApple : outlinedApple;

    case 'Citrus':
      return selected ? filledLemon : outlinedLemon;

    case 'Clove':
      return selected ? filledClove : outlinedClove;

    case 'Pepper':
      return selected ? filledPepper : outlinedPepper;

    case 'Earth':
      return selected ? filledEarth : outlinedEarth;

    case 'Floral':
      return selected ? filledFlower : outlinedFlower;

    case 'Pine':
      return selected ? filledPine : outlinedPine;
  }
};

// export const renderSmell = smell => {
//   switch (smell) {
//     case 'Fruity':
//       return (
//         <button>
//           <img className="filled" src={filledApple} />
//           <img className="outlined" src={outlinedApple} />
//         </button>
//       );

//     case 'Citrus':
//       return (
//         <button>
//           <img className="filled" src={filledLemon} />
//           <img className="outlined" src={outlinedLemon} />
//         </button>
//       );

//     case 'Clove':
//       return (
//         <button>
//           <img className="filled" src={filledClove} />
//           <img className="outlined" src={outlinedClove} />
//         </button>
//       );

//     case 'Pepper':
//       return (
//         <button>
//           <img className="filled" src={filledPepper} />
//           <img className="outlined" src={outlinedPepper} />
//         </button>
//       );

//     case 'Earth':
//       return (
//         <button>
//           <img className="filled" src={filledEarth} />
//           <img className="outlined" src={outlinedEarth} />
//         </button>
//       );

//     case 'Floral':
//       return (
//         <button>
//           <img className="filled" src={filledFlower} />
//           <img className="outlined" src={outlinedFlower} />
//         </button>
//       );

//     case 'Pine':
//       return (
//         <button>
//           <img className="filled" src={filledPine} />
//           <img className="outlined" src={outlinedPine} />
//         </button>
//       );
//   }
// };
