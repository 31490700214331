import React from 'react';
import PrismicRichText from '../components/PrismicRichText';
import { get, map } from 'lodash';
import { styled } from 'styled-components';
import Link from './../components/Link';

const StlFigure = styled.figure`
  img {
    height: 28px;
    position: relative;
    top: 2px;

    @media only screen and (min-width: 1100px) {
      height: 45px;
    }
  }
`;

const StlLogosWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 10px;
  @media only screen and (min-width: 1100px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: start;
    max-width: 950px;
    margin: 40px auto;
  }
`;

const NewLogoSection = ({ slice }) => {
  const background = get(slice, 'primary.background_style');
  const showBackground = background === 'Dark Flower' ? 'dark-flower' : 'dark-blue-splatter';

  return (
    <div className={showBackground}>
      <div className="background-wrapper padding">
        <div className="wrapper">
          <div className="text-center">
            <PrismicRichText render={get(slice, 'primary.content')} />
          </div>
          <StlLogosWrapper>
            {map(slice.items, (el, i) => {
              return (
                get(el, 'link_url.url') && (
                  <Link
                    key={i}
                    url={get(el, 'link_url.url')}
                    to={get(el, 'link_url.uid')}
                    target={get(el, 'link_url.target')}
                    className="outlined-button-style">
                    <StlFigure className="contain-img flex-center">
                      <img src={get(el, 'logo.url')} alt={get(el, 'logo.alt')} />
                    </StlFigure>
                  </Link>
                )
              );
            })}
          </StlLogosWrapper>
        </div>
      </div>
    </div>
  );
};

export default NewLogoSection;
