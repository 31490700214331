import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty, map } from 'lodash';
import { useLocation } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import PrismicRichText from '../components/PrismicRichText';
import { styled } from 'styled-components';
import { SimpleLink } from '../helpers/renderButtonsV2';
import filledEye from '../imagesV2/icons/strain-details/primary-eye.png';
import outlinedEye from '../imagesV2/icons/strain-details/secondary-eye.png';
import filledFlower from '../imagesV2/icons/strain-details/primary-flower.png';
import outlinedFlower from '../imagesV2/icons/strain-details/secondary-thc.png';
import filledMouth from '../imagesV2/icons/strain-details/primary-mouth.png';
import outlinedMouth from '../imagesV2/icons/strain-details/secondary-mouth.png';
import filledChain from '../imagesV2/icons/strain-details/primary-chain.png';
import outlinedChain from '../imagesV2/icons/strain-details/secondary-chain.png';
import filledBrain from '../imagesV2/icons/strain-details/primary-brain.png';
import outlinedBrain from '../imagesV2/icons/strain-details/secondary-brain.png';
import filledDrop from '../imagesV2/icons/strain-details/primary-drop.png';
import outlinedDrop from '../imagesV2/icons/strain-details/secondary-drop.png';
import sativaDram from '../imagesV2/drams/sativa-dram.png';
import hybridDram from '../imagesV2/drams/hybrid-dram.png';
import indicaDram from '../imagesV2/drams/indica-dram.png';
import filledCBD from '../imagesV2/icons/strain-details/primary-cbd.png';
import outlinedCBD from '../imagesV2/icons/strain-details/secondary-cbd.png';
import mobileHero from '../imagesV2/backgrounds&Textures/strain-header-mobile.jpg';
import desktopHero from '../imagesV2/backgrounds&Textures/strain-header-desktop.jpg';
import { renderTerpenes } from '../helpers/terpenes';
import CarouselV2 from '../components/Carousel/carouselV2';
import CarouselInfoSlide from '../components/CarouselInfoSlide';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Loader from '../components/Loader';

const StlDescription = styled.div`
  h1 {
    margin-bottom: 16px;
  }
  .strain-img {
    width: 210px;
    height: 210px;
    margin: 36px auto 0;
  }

  @media only screen and (min-width: 900px) {
    h1 {
      margin-bottom: 32px;
    }

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

const StlDram = styled.div`
  padding: 0 50px;
  margin: auto;
  figure {
    max-width: 400px;
    margin: 0 auto;
  }
  @media only screen and (min-width: 1200px) {
    margin: 0;
    width: 500px;
    padding: 50px;
    display: flex;
    justify-content: flex-start;
    position: relative;
    bottom: 75px;
  }
`;

const StlStrainHero = styled.div`
  min-height: 700px;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 900px) {
    min-height: 900px;

    .button-container {
      margin-top: 50px;
    }
  }

  @media only screen and (min-width: 1400px) {
    .button-container {
      margin-top: 70px;
    }
  }
`;

const StlHeroContent = styled.div`
  position: relative;
  z-index: 2;
  padding: 100px 40px;
  text-align: center;
  max-width: 1600px;
  margin: auto;

  .strain-img {
    margin-top: 40px;
    max-width: 800px;
  }

  @media only screen and (min-width: 900px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 80px;
    text-align: left;
  }
  @media only screen and (min-width: 900px) {
    padding: 200px 80px;
  }
`;

export default function StrainDetailPage(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const handleLoading = props.loading;
  const theme = useTheme();
  const layoutBreakpoint = useMediaQuery(theme.breakpoints.down('650'));

  const whichPage = path => {
    if (path === '/404') {
      return '404';
    } else {
      return path.split('/')[2];
    }
  };

  const pagePath = whichPage(location.pathname);
  const pageLoading = useSelector(state => get(state, 'prismic.strains.loading', true));
  const page = useSelector(state => get(state, 'prismic.strains.data', false));

  const strainName = get(page, 'name[0].text');
  const strainImage = get(page, 'image.url');
  const description = get(page, 'description');

  const averageTHC = get(page, 'average_thc[0].text');
  const averageCBD = get(page, 'average_cbd[0].text');
  const flavor = get(page, 'flavor[0].text');
  const lineage = get(page, 'lineage[0].text');
  const effects = get(page, 'effects[0].text');
  const terpenes = get(page, 'terpenes[0]');
  const strainType = get(page, 'type');

  const smallScreenInfo = [
    !isEmpty(strainType) && (
      <CarouselInfoSlide
        activeImage={filledEye}
        inactiveImage={outlinedEye}
        carouselIconStyle={true}
        copyClassName="strain-info-slider">
        <p className="poppins-semibold title">Dominance</p>
        <p className="content oswaldBold uppercase">{strainType}</p>
      </CarouselInfoSlide>
    ),

    !isEmpty(averageTHC) && (
      <CarouselInfoSlide
        activeImage={filledFlower}
        inactiveImage={outlinedFlower}
        carouselIconStyle={true}
        copyClassName="strain-info-slider">
        <p className="poppins-semibold title">Avg thc %</p>
        <p className="content oswaldBold uppercase">{averageTHC}</p>
      </CarouselInfoSlide>
    ),

    !isEmpty(flavor) && (
      <CarouselInfoSlide
        activeImage={filledMouth}
        inactiveImage={outlinedMouth}
        carouselIconStyle={true}
        copyClassName="strain-info-slider">
        <p className="poppins-semibold title">flavor</p>
        <p className="content oswaldBold uppercase">{flavor}</p>
      </CarouselInfoSlide>
    ),
    !isEmpty(lineage) && (
      <CarouselInfoSlide
        activeImage={filledChain}
        inactiveImage={outlinedChain}
        carouselIconStyle={true}
        copyClassName="strain-info-slider">
        <p className="poppins-semibold title">Lineage</p>
        <p className="content oswaldBold uppercase">{lineage}</p>
      </CarouselInfoSlide>
    ),

    !isEmpty(effects) && (
      <CarouselInfoSlide
        activeImage={filledBrain}
        inactiveImage={outlinedBrain}
        carouselIconStyle={true}
        copyClassName="strain-info-slider">
        <p className="poppins-semibold title">Effects</p>
        <p className="content oswaldBold uppercase">{effects}</p>
      </CarouselInfoSlide>
    ),
    !isEmpty(averageCBD) && (
      <CarouselInfoSlide
        activeImage={filledCBD}
        inactiveImage={outlinedCBD}
        carouselIconStyle={true}
        copyClassName="strain-info-slider">
        <p className="poppins-semibold title">Average CBD</p>
        <p className="content oswaldBold uppercase">{averageCBD}</p>
      </CarouselInfoSlide>
    ),
    !isEmpty(terpenes) && (
      <CarouselInfoSlide
        activeImage={filledDrop}
        inactiveImage={outlinedDrop}
        carouselIconStyle={true}
        copyClassName="strain-info-slider">
        <p className="poppins-semibold title">Terpenes</p>
        {renderTerpenes(terpenes)}
      </CarouselInfoSlide>
    )
  ];

  const filterSmallScreenInfo = smallScreenInfo.filter(el => el);

  const dramImage = () => {
    if (strainType === 'Sativa') {
      return sativaDram;
    }
    if (strainType === 'Hybrid') {
      return hybridDram;
    } else {
      return indicaDram;
    }
  };

  useEffect(() => {
    dispatch({
      type: 'PRISMIC_LOAD_SINGLE_BY_UID',
      key: 'strains',
      id: pagePath
    });
  }, [dispatch, pagePath]);

  useEffect(() => {
    if (!pageLoading) {
      handleLoading(false);
    }
  }, [pageLoading, handleLoading]);

  const handleBack = () => {
    window.history.back();
  };

  if (!page && !pageLoading) {
    return <Redirect to={'/404'} />;
  }
  if (pageLoading) {
    return (
      <div className="loading-screen">
        <Loader $minHeight="800px" />
      </div>
    );
  }

  return (
    !pageLoading &&
    page && (
      <div className="page">
        <div>
          <div className="back-button" onClick={handleBack}>
            &#60; back
          </div>
          {/* START HERO */}

          <StlStrainHero className="new-hero">
            <figure className="fullscreen-background">
              <img
                className="fullscreen-background"
                src={layoutBreakpoint ? mobileHero : desktopHero}
                alt=""
                role="presentation"
              />
            </figure>

            <StlHeroContent>
              <StlDescription>
                <h1>{strainName}</h1>
                <PrismicRichText render={description} />
                <div className="button-container center-left">
                  <SimpleLink to={'shop'} variant="contained" text="shop" className="simple-link" />
                </div>
              </StlDescription>
              <figure className="contain-img strain-img">
                <img src={strainImage} alt={strainName} />
              </figure>
            </StlHeroContent>
          </StlStrainHero>
          {/* END HERO */}
          <div className="dark-flower">
            <div className="background-wrapper content-dram-section">
              <StlDram>
                <figure className="contain-img">
                  <img src={dramImage()} />
                </figure>
              </StlDram>
              {layoutBreakpoint ? (
                <CarouselV2 className="info-carousel info-carousel-padding">
                  {map(filterSmallScreenInfo, el => {
                    return el;
                  })}
                </CarouselV2>
              ) : (
                <div className="strain-info-section">
                  {strainType && (
                    <div className="strain-info">
                      <figure className="contain-img">
                        <img role="presentation" alt="" src={filledEye} />
                      </figure>
                      <div>
                        <p className="poppins-semibold title">Dominance</p>
                        <p className="content oswaldBold uppercase">{strainType}</p>
                      </div>
                    </div>
                  )}
                  {averageTHC && (
                    <div className="strain-info">
                      <figure className="contain-img">
                        <img role="presentation" alt="" src={filledFlower} />
                      </figure>
                      <div>
                        <p className="poppins-semibold title">Avg thc %</p>
                        <p className="content oswaldBold uppercase">{averageTHC}</p>
                      </div>
                    </div>
                  )}

                  {flavor && (
                    <div className="strain-info">
                      <figure className="contain-img">
                        <img role="presentation" alt="" src={filledMouth} />
                      </figure>
                      <div>
                        <p className="poppins-semibold title">flavor</p>
                        <p className="content oswaldBold uppercase">{flavor}</p>
                      </div>
                    </div>
                  )}
                  {lineage && (
                    <div className="strain-info">
                      <figure className="contain-img">
                        <img role="presentation" alt="" src={filledChain} />
                      </figure>
                      <div>
                        <p className="poppins-semibold title">Lineage</p>
                        <p className="content oswaldBold uppercase">{lineage}</p>
                      </div>
                    </div>
                  )}
                  {effects && (
                    <div className="strain-info">
                      <figure className="contain-img">
                        <img role="presentation" alt="" src={filledBrain} />
                      </figure>
                      <div>
                        <p className="poppins-semibold title">Effects</p>
                        <p className="content oswaldBold uppercase">{effects}</p>
                      </div>
                    </div>
                  )}
                  {averageCBD && (
                    <div className="strain-info">
                      <figure className="contain-img">
                        <img role="presentation" alt="" src={filledCBD} />
                      </figure>
                      <div>
                        <p className="poppins-semibold title">Average CBD</p>
                        <p className="content oswaldBold uppercase">{averageCBD}</p>
                      </div>
                    </div>
                  )}

                  {!isEmpty(terpenes) && (
                    <div className="strain-info">
                      <figure className="contain-img">
                        <img role="presentation" alt="" src={filledDrop} />
                      </figure>

                      <div className="terpenes-wrapper desktop">
                        <p className="poppins-semibold title">Terpenes</p>
                        {renderTerpenes(terpenes)}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
}
