import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import StrainCard from '../StrainCard';

export default function MatchingStrains({ id }) {
  const dispatch = useDispatch();

  const loading = useSelector(state => get(state, `prismic.strains-${id}.loading`, true));
  const data = useSelector(state => get(state, `prismic.strains-${id}.data`, false));

  const strainImage = get(data, 'data.image.url');
  const strainID = get(data, 'uid');
  const strainName = get(data, 'data.name[0].text');

  useEffect(() => {
    dispatch({
      type: 'PRISMIC_LOAD_SINGLE_BY_UID',
      key: 'strains',
      reduxKey: `strains-${id}`,
      raw: true,
      id
    });
  }, [dispatch, id]);

  return (
    !loading &&
    data && (
      <StrainCard
        strainID={strainID}
        strainImage={strainImage}
        strainName={strainName}
        className="mr-10 strain-card strain-by-id"
      />
    )
  );
}
