import React, { useEffect, useState } from 'react';
import { PrismicRichText } from '@prismicio/react';
import CustomCarousel from '../components/Carousel';
import Grid from '@material-ui/core/Grid';
import renderButtons from './../helpers/renderButtons';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';

export default function ProductGallerySlice({slice}) {
  const dispatch = useDispatch();

  const [products, setProducts] = useState(false);

  const loading = useSelector(state => get(state, 'prismic.products_list.loading', true));
  const data = useSelector(state => get(state, 'prismic.products_list.data', false));

  const backgroundStyle = {
    backgroundColor: slice.primary.background_color,
    color: slice.primary.text_color
  };

  useEffect(() => {
    dispatch({
      type: 'PRISMIC_LOAD_REPEATABLE',
      key: 'products',
      reduxKey: 'products_list'
    });
  }, [dispatch]);

  useEffect(() => {
    if (data && !loading) {
      const sortedData = data.sort((a, b) => {
        return a.data.order - b.data.order;
      });
      setProducts(sortedData);
    }
  }, [data, loading]);

  const generateCustomButtons = product => {
    const buttons = [
      {
        active: true,
        cta_link: {
          uid: `products/${product.uid}`
        },
        cta_text: 'Learn More',
        button_variant: 'outlined',
        button_color: 'primary'
      },
      {
        active: true,
        cta_link: {
          uid: 'shop'
        },
        cta_text: 'Shop Now',
        button_variant: 'contained',
        button_color: 'primary'
      }
    ];
    return buttons;
  };
  const renderCarousel = items => {
    return (
      <div className="gallery-carousel-container box-padding">
        <CustomCarousel items={items} showIndicators={false} thumbs={true} />
      </div>
    );
  };

  return (
    products &&
    products.map((product, i) => (
      <section className="product-gallery-section cool-margin" style={backgroundStyle} key={i}>
        <Grid container className="wrapper">
          <Grid item xs={12} md={6} className="carousel-wrapper">
            {renderCarousel(product.data.gallery)}
          </Grid>
          <Grid item xs={12} md={6} className="text-content box-padding">
            <h3>{product.data.product_name[0].text}</h3>
            <PrismicRichText field={product.data.product_detail} />
            <div className="button-container">{renderButtons(generateCustomButtons(product))}</div>
          </Grid>
        </Grid>
      </section>
    ))
  );
}
