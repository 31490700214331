import React from 'react';
import PrismicRichText from '../components/PrismicRichText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import renderButtons from '../helpers/renderButtonsV2';
import { useTheme } from '@material-ui/core/styles';
import { get } from 'lodash';
import Video from './NewVideo';
import { styled } from 'styled-components';
import classNames from 'classnames';
import llrGoldHero from '../imagesV2/LLR/gold-splash-hero.png';

const StlHero = styled.section`
  min-height: 700px;
  height: 100%;
  position: relative;
  @media only screen and (min-width: 900px) {
    min-height: 900px;

    .button-container {
      margin-top: 50px;
    }
  }

  @media only screen and (min-width: 1400px) {
    .button-container {
      margin-top: 70px;
    }
  }
`;

const StlCopy = styled.div`
  height: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex-direction: column;
  z-index: 4;
  text-align: center;
  padding: 100px 25px 0;
  max-width: 600px;
  margin: auto;

  h1 {
    max-width: 344px;
    margin-bottom: 16px;
  }
  p {
    font-family: 'Poppins-Semibold';
  }

  @media only screen and (min-width: 900px) {
    margin: 0;
    align-items: flex-start;
    justify-content: center;
    padding: 0 50px;
    text-align: left;
    max-width: 900px;

    h1 {
      max-width: 900px;
      margin-bottom: 32px;
    }

    p:not(button p) {
      font-size: 2rem;
      margin: 0 0 10px 10px;
      max-width: 500px;
    }

    &.llr-copy {
      h1 {
        max-width: 700px;
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    h1 {
      margin-bottom: 3rem;
    }
  }

  @media only screen and (min-width: 1400px) {
    padding: 0 0 0 200px;
    &.llr-copy {
      padding: 0 0 0 100px;
    }
  }
`;

const NewHero = ({ slice }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('905'));
  const mobileVideo = get(slice, 'primary.hero_video_mobile.url');
  const desktopVideo = get(slice, 'primary.hero_video_desktop.url');
  const mobileImage = get(slice, 'primary.hero_image.Mobile.url');
  const desktopImage = get(slice, 'primary.hero_image.url');
  const hasVideo = mobileVideo && desktopVideo;
  const showVideo = isMobile ? mobileVideo : desktopVideo;
  const posterImage = isMobile
    ? get(slice, 'primary.poster_img.url')
    : get(slice, 'primary.poster_img.Mobile.url');
  const copy = get(slice, 'primary.copy');
  const buttons = get(slice, 'items');
  const isLLR = get(slice, 'primary.add_llr_page_art') === true;

  return (
    <StlHero className={classNames('new-hero', isLLR && 'llr-hero')}>
      {hasVideo && <Video video={showVideo} posterImage={posterImage} />}
      {desktopImage && (
        <figure className="fullscreen-background">
          <img
            className="fullscreen-background"
            src={isMobile ? mobileImage : desktopImage}
            alt={
              isMobile
                ? get(slice, 'primary.hero_image.Mobile.alt')
                : get(slice, 'primary.hero_image.alt')
            }></img>
        </figure>
      )}
      <StlCopy className={classNames(isLLR && 'llr-copy')}>
        <PrismicRichText render={copy} />
        <div
          className={classNames(
            isLLR && 'llr-button-container',
            'button-container center-left gap'
          )}>
          {renderButtons(buttons)}
        </div>
      </StlCopy>
      {isLLR && (
        <figure className={classNames('llr-gold-stream-hero cover-img')}>
          <img src={llrGoldHero} alt="" role="presentation" />
        </figure>
      )}
    </StlHero>
  );
};

export default NewHero;
