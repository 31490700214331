import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import CustomRichText from '../PrismicRichText';
import linkResolver from './../../helpers/linkResolver';
import AccessibilitySwitch from '../AccessibilitySwitch';
import { styled } from 'styled-components';
import CookingSettingsLink from './CookingSettingsLink';
import Link from '../Link';

const StlStatement = styled.div`
  margin-bottom: 20px;
  @media screen and (min-width: 800px) {
    margin-bottom: 32px;
  }
`;

export default function Footer() {
  const componentLoading = useSelector(state => get(state, 'prismic.footer.loading', true));
  const component = useSelector(state => get(state, 'prismic.footer.data', false));
  const year = new Date().getFullYear();

  const renderFooterColumns = columns => {
    return columns.map((el, i) => (
      <div key={i} className="footer-section ">
        <div className="heading">
          <CustomRichText linkResolver={linkResolver} render={el?.column_title} />
        </div>
        <div className="content">
          <CustomRichText linkResolver={linkResolver} render={el?.content} />
        </div>
      </div>
    ));
  };

  const renderSocialLinks = socialLinks => {
    return socialLinks.map((el, i) => (
      <Link
        className="social-link"
        key={i}
        url={el.link.url}
        target="_blank"
        rel="noopener noreferrer">
        <img className="social-icon" src={get(el, 'icon.url')} alt="Social Icon"></img>
      </Link>
    ));
  };

  return (
    !componentLoading &&
    component && (
      <footer>
        <StlStatement>
          <p>We’re on a mission to normalize, professionalize, and revolutionize cannabis.</p>
        </StlStatement>
        <div className="link-grid">
          {renderFooterColumns(component.footer)}
          <CookingSettingsLink />
          <div className="social">
            <h2>Follow Us</h2>
            {renderSocialLinks(component.social_links)}
          </div>
        </div>
        <div className="footer-section">
          <div className="accessibility">
            <AccessibilitySwitch />
          </div>
          <div className="copyright mt-15">
            <p>© {year} Cresco Cannabis. All rights reserved.</p>
          </div>
        </div>
      </footer>
    )
  );
}
