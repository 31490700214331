import React from 'react';
import TextSlice from './TextSlice';
import FullWidthImageSlice from './FullWidthImageSlice';
import TextSlider from './TextSlider';
import PhotoSection from './PhotoSection';
import LogoSection from './LogoSection';
import ProductFeatureSlice from './ProductFeatureSlice';
import ProductGallerySlice from './ProductGallerySlice';
import BenefitFeatureSlice from './BenefitFeatureSlice';
import ColumnSlice from './ColumnSlice';
import StandardHero from './StandardHero';
import SpecialHero from './SpecialHero';
import VideoSlice from './VideoSlice';
import FAQSlice from './FAQSlice';
import VideoHero from './VideoHero';
import SpecialHeroV2 from './SpecialHeroV2';
import TerpenesFeatureSlice from './TerpenesFeatureSlice';
import TerpeneFinderSlice from './TerpeneFinderSlice';
import BlogSlice from './BlogSlice';
import HeroHalfImgContent from './HeroHalfImgAndContent';
import ImageCopyBlocks from './ImageCopyBlocks';
import YoutubeSlice from './YoutubeSlice';

// NEW SLICES
import NewHero from './NewHero';
import ContentSlider from './NewContentSlider';
import ScrollingStrainsList from './ScrollingStrainsList';
// Replaces Logo Section
import NewLogoSection from './NewLogoSection';
// Image with Content replaces Photo Section
import ImageWithContent from './ImageWithContent';
import NewLogoSectionWithContent from './NewLogoSectionWithContent';
import ImageWithContentVersion2 from './ImageWithContentVersion2';
import StoreLocatorSlice from './StoreLocatorSlice';
import ContentWithSliderOnMobile from './ContentWithSliderOnMobile';
import DoubleContentWithImage from './DoubleContentWithImage';
import LLRInfo from './LLRInfo';
import SpecialImageContent from './SpecialImageContent';
import FactSlice from './FactSlice';
import PartnerSlice from './PartnerSlice';
import CTASlice from './CTASlice';
import StrainFinderSlice from './StrainFinderSlice';
import TextBackgroundSlice from './TextBackgroundSlice';
import VideoThumbSlice from './VideoThumbSlice';

export default function Slices({ slices }) {
  if (!slices) return null;

  return (
    <div className="slice-wrapper">
      {slices.map((slice, index) => (
        <div key={index} className="slice-single">
          {slice.slice_type === 'product_gallery_slice' && <ProductGallerySlice slice={slice} />}
          {slice.slice_type === 'column_slice' && <ColumnSlice slice={slice} />}
          {slice.slice_type === 'text_slice' && <TextSlice slice={slice} />}
          {slice.slice_type === 'text_slider' && <TextSlider slice={slice} />}
          {slice.slice_type === 'faq_slice' && <FAQSlice slice={slice} />}

          {slice.slice_type === 'full_width_image_slice' && <FullWidthImageSlice slice={slice} />}
          {slice.slice_type === 'questions_slice' && <TextSlider slice={slice} />}
          {(slice.slice_type === 'full_width_text_image_slice' ||
            slice.slice_type === 'background_image_text_slice') && (
            <TextBackgroundSlice slice={slice} />
          )}
          {(slice.slice_type === 'photo_section' ||
            slice.slice_type === 'image_content_section') && <PhotoSection slice={slice} />}
          {slice.slice_type === 'logo_section' && <LogoSection slice={slice} />}
          {slice.slice_type === 'product_feature_slice' && <ProductFeatureSlice slice={slice} />}
          {slice.slice_type === 'benefit_feature_slice' && <BenefitFeatureSlice slice={slice} />}
          {slice.slice_type === 'strain_finder_slice' && <StrainFinderSlice slice={slice} />}
          {slice.slice_type === 'standard_hero' && <StandardHero slice={slice} />}
          {slice.slice_type === 'special_hero' && <SpecialHero slice={slice} />}
          {slice.slice_type === 'video_embed_slice' && <VideoSlice slice={slice} />}

          {slice.slice_type === 'special_image_content' && <SpecialImageContent slice={slice} />}
          {slice.slice_type === 'cta_slice' && <CTASlice slice={slice} />}
          {slice.slice_type === 'video_hero' && <VideoHero slice={slice} />}
          {slice.slice_type === 'special_hero_v2' && <SpecialHeroV2 slice={slice} />}
          {slice.slice_type === 'terpenes_feature_slice' && <TerpenesFeatureSlice slice={slice} />}
          {slice.slice_type === 'terpene_finder_slice' && <TerpeneFinderSlice slice={slice} />}
          {slice.slice_type === 'blog_slice' && <BlogSlice slice={slice} />}
          {slice.slice_type === 'hero_half_image_half_content' && (
            <HeroHalfImgContent slice={slice} />
          )}
          {slice.slice_type === 'fact_slice' && <FactSlice slice={slice} />}
          {slice.slice_type === 'partner_slice' && <PartnerSlice slice={slice} />}
          {slice.slice_type === 'video_thumb_slice' && <VideoThumbSlice slice={slice} />}
          {slice.slice_type === 'image_copy_blocks' && <ImageCopyBlocks slice={slice} />}
          {slice.slice_type === 'llr_chart_slice' && <LLRChartSlice slice={slice} />}
          {slice.slice_type === 'llr_custom_hero' && <LLRCustomHero slice={slice} />}
          {slice.slice_type === 'youtube_slice' && <YoutubeSlice slice={slice} />}
          {/* NEW SLICES */}
          {slice.slice_type === 'new_hero' && <NewHero slice={slice} />}
          {slice.slice_type === 'content_slider' && <ContentSlider slice={slice} />}
          {slice.slice_type === 'scrolling_strains_list' && <ScrollingStrainsList slice={slice} />}
          {slice.slice_type === 'new_logo_section' && <NewLogoSection slice={slice} />}
          {slice.slice_type === 'new_image_with_content_section' && (
            <ImageWithContent slice={slice} />
          )}
          {slice.slice_type === 'new_logo_section_with_copy' && (
            <NewLogoSectionWithContent slice={slice} />
          )}
          {slice.slice_type === 'new_image_with_content_section_version2' && (
            <ImageWithContentVersion2 slice={slice} />
          )}
          {slice.slice_type === 'store_locator_slice' && <StoreLocatorSlice slice={slice} />}

          {slice.slice_type === 'content_with_slider_on_mobile' && (
            <ContentWithSliderOnMobile slice={slice} />
          )}
          {slice.slice_type === 'double_content_with_image' && (
            <DoubleContentWithImage slice={slice} />
          )}
          {slice.slice_type === 'llr_info_' && <LLRInfo slice={slice} />}
        </div>
      ))}
    </div>
  );
}
