import React from 'react';
import PrismicRichText from '../components/PrismicRichText';
import { get } from 'lodash';
import { Carousel } from 'react-responsive-carousel';

export default function FactSlice({ slice }) {
  const backgroundStyle = {
    color: slice.primary.text_color,
    backgroundColor: slice.primary.background_color,
    backgroundImage: get(slice, 'primary.background_image.url'),
    backgroundPosition: 'center center',
    backgroundSize: 'cover'
  };

  return (
    <section className="fact-slice section-padding" style={backgroundStyle}>
      <div className="wrapper">
        <div className="text-content">
          <PrismicRichText render={slice.primary.content} />
        </div>

        <div className="fact-slider">
          <Carousel thumbs={false} showStatus={false} showIndicators={false} showArrows={true}>
            {slice.items.map((fact, i) => (
              <div className="fact-slide">
                <PrismicRichText render={fact.content} key={i} />
              </div>
            ))}
          </Carousel>
        </div>

        <div className="text-content-bottom">
          <PrismicRichText render={slice.primary.postscript_content} />
        </div>
      </div>
    </section>
  );
}
