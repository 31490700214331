import React, { useEffect, useState } from 'react';
import { useAllPrismicDocumentsByType } from '@prismicio/react';
import StrainCard from '../components/StrainCard';
import { get, map, filter, take, shuffle } from 'lodash';
import PrismicRichText from '../components/PrismicRichText';
import { styled } from 'styled-components';
import HorizontalSlider from '../components/HorizontalSlider';
import { SimpleLink } from '../helpers/renderButtonsV2';
import Loader from '../components/Loader';

const StlCopy = styled.div`
  text-align: center;

  @media only screen and (min-width: 900px) {
    margin-bottom: 30px;
  }
`;

const StlCopyBtnWrapper = styled.div`
  padding: 80px 20px 40px;

  @media only screen and (min-width: 900px) {
    padding: 160px 30px 80px;

    .button-container {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      gap: 30px;
    }
  }
`;

const StlSliderWrapper = styled.div`
  padding-bottom: 80px;

  @media only screen and (min-width: 900px) {
    padding-bottom: 160px;
  }
`;

const ScrollingStrainsList = ({ slice }) => {
  const [data, { state }] = useAllPrismicDocumentsByType('strains');
  const loading = state === 'loading';
  const copy = get(slice, 'primary.content');
  const [strains, setStrains] = useState([]);

  useEffect(() => {
    if (!loading && data) {
      const filterFeatured = filter(data, strain => {
        return !strain.tags.includes('featured');
      });
      const randomizedStrains = shuffle(filterFeatured);

      setStrains(take(randomizedStrains, 15));
    }
  }, [loading, data]);

  const randomize = () => {
    setStrains(take(shuffle(data), 15));
  };

  return (
    <div className="dark-blue-splatter">
      {loading ? (
        <Loader />
      ) : (
        strains && (
          <StlSliderWrapper className="background-wrapper ">
            <StlCopyBtnWrapper>
              <StlCopy>
                <PrismicRichText render={copy} />
              </StlCopy>
              <div className="button-container gap center text-center">
                <SimpleLink to="strains" variant="contained" text="Explore All" />
                <button onClick={() => randomize()} className="outlined-button scale-text">
                  <p>Randomize</p>
                </button>
              </div>
            </StlCopyBtnWrapper>
            <HorizontalSlider scrollContainerClassName="scroller">
              {map(strains, (el, i) => (
                <StrainCard
                  key={i}
                  strainID={get(el, 'uid')}
                  strainName={get(el, 'data.name[0].text')}
                  strainImage={get(el, 'data.image.url')}
                  className="mr-10 strain-card"
                />
              ))}
            </HorizontalSlider>
          </StlSliderWrapper>
        )
      )}
    </div>
  );
};

export default ScrollingStrainsList;
