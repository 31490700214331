import React, { useState, useEffect } from 'react';
import PrismicRichText from '../components/PrismicRichText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { styled } from 'styled-components';
import { lightBlue, darkBlue } from '../constants/colors';

const StlWrapper = styled.div`
  border-bottom: 1px solid ${lightBlue};

  .icon-button {
    padding-top: 12px;
    @media only screen and (min-width: 900px) {
      padding-top: 26px;
    }
  }

  .question {
    font-size: 1.4rem;
    padding: 8px 5px 5px 5px;
    @media only screen and (min-width: 900px) {
      font-size: 2.6rem;
      padding: 20px 16px 16px 0;
    }
  }

  .answer {
    padding: 8px;
  }
`;

const StlQuestionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StlCollapse = styled(Collapse)`
  background: ${lightBlue};
  .answer p {
    color: ${darkBlue};
  }
`;

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <StlWrapper>
      <StlQuestionWrapper onClick={() => setOpen(!open)}>
        <div>
          <Box className="question" margin={1}>
            <PrismicRichText render={row?.question} />
          </Box>
        </div>
        <div>
          <IconButton aria-label="expand row" size="small" className="icon-button">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </div>
      </StlQuestionWrapper>

      <div>
        <div style={{ padding: 0 }} colSpan={2}>
          <StlCollapse in={open} timeout="auto" unmountOnExit>
            <Box className="answer" margin={1}>
              <PrismicRichText render={row?.answer} />
            </Box>
          </StlCollapse>
        </div>
      </div>
    </StlWrapper>
  );
}

const StlContent = styled.div`
  margin-bottom: 40px;
  @media only screen and (min-width: 900px) {
    margin-bottom: 80px;
  }
`;

const StlTable = styled(Table)`
  max-width: 700px;
  margin: auto;
`;

export default function RenderFAQSection({ slice }) {
  const dispatch = useDispatch();
  const loading = useSelector(state => get(state, 'prismic.faqs.loading', true));
  const data = useSelector(state => get(state, 'prismic.faqs.data', false));

  useEffect(() => {
    dispatch({
      type: 'PRISMIC_LOAD_SINGLE_PAGE',
      key: 'faqs'
    });
  }, [dispatch]);

  if (!loading && !data) {
    return null;
  }

  const renderFAQs = items => {
    return items.map((el, i) => <Row key={i} row={el} />);
  };
  return (
    !loading &&
    data && (
      <div className="dark-flower background-repeat">
        <div className="background-wrapper">
          <TableContainer className="padding wrapper">
            <StlContent className="text-center">
              <PrismicRichText render={get(slice, 'primary.content')} />
            </StlContent>
            <StlTable aria-label="collapsible table">
              <TableBody>{renderFAQs(data.faqs)}</TableBody>
            </StlTable>
          </TableContainer>
        </div>
      </div>
    )
  );
}
