import React from 'react';
import classNames from 'classnames';

const CarouselInfoSlide = ({
  activeImage,
  carouselIconStyle,
  inactiveImage,
  children,
  copyClassName,
  onClick,
  optionalCopy,
  ...rest
}) => {
  return (
    <div className="info-slide" onClick={onClick} {...rest}>
      <figure className={classNames(carouselIconStyle && 'carousel-icon-style', 'contain-img')}>
        <img className="active-icon" role="presentation" alt="" src={activeImage} />
        <img className="inactive-icon" role="presentation" alt="" src={inactiveImage} />
      </figure>
      <div className={classNames(copyClassName, 'attributes-wrapper')}>
        {optionalCopy && <h2>{optionalCopy}</h2>}
        {children}
      </div>
    </div>
  );
};

export default CarouselInfoSlide;
